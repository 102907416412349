import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { Button, PhotoSlider, Dialog } from '@components';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { useAppSelector } from '@tools/hooks/redux';
import { Planogram } from '@types/planogram';
import { PlanogramTypesName } from '@apiFeature/planograms/types';
import { Book } from '@navigation/Book';
import { useRoles } from '@tools/hooks/useRoles';
import styles from './PlanogramsList.module.scss';

const PlanogramsList: FC<Planogram> = (props) => {
    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { clientPermission } = useRoles();
    const { t } = useTranslation('translation');
    const navigate = useNavigate();

    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<boolean>(false);

    const {
        id,
        name,
        project,
        type,
        text,
        file_links,
        deletePlanogramData,
        planogramsPermissionWebCreate,
    } = props;
    const { full_name } = project || {};

    const serializedFeedItem = {
        [t('common.project')]: {
            value: full_name ?? '-',
        },
        [t('catalog.planograms.type')]: {
            value: PlanogramTypesName[type] ?? '-',
        },
        [t('common.description')]: {
            value: text ?? '-',
        },
    };

    const openPlanogram = () => {
        navigate(`${Book.catalog.planograms}/${id}`);
    };

    const openDeleteDialog = () => {
        setOpenDeleteConfirm(true);
    };

    const handleCloseModal = () => {
        setOpenDeleteConfirm(false);
    };

    const deletePlanogram = () => {
        handleCloseModal();
        deletePlanogramData(id);
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.item}>
                    <div className={styles.name}>{name}</div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <ListMapper
                        items={permissionCheck(authRoles, serializedFeedItem)}
                        className={styles.serializedFeedItem}
                    />
                    {file_links?.length > 0 && (
                        <div className={styles.slider}>
                            <PhotoSlider
                                id={id}
                                photos={file_links}
                                type="carousel"
                                hasDeleteBtn={!clientPermission}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.footer}>
                <Button color="secondary" variant="outlined" onClick={openPlanogram}>
                    {t('buttons.view')}
                </Button>
                {planogramsPermissionWebCreate && (
                    <Button color="secondary" variant="outlined" onClick={openDeleteDialog}>
                        {t('buttons.delete')}
                    </Button>
                )}
            </div>
            <Dialog
                id="deletePlanogramDialog"
                isOpenDialog={openDeleteConfirm}
                setCloseDialog={handleCloseModal}
                className={styles.dialog}
                title={t('messages.planogramsDeleteSuccess')}
                actions={
                    <>
                        <Button variant="outlined" color="secondary" onClick={deletePlanogram}>
                            {t('common.yes')}
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                            {t('common.no')}
                        </Button>
                    </>
                }
            >
                {t('messages.planogramsDeleteConfirm')}
            </Dialog>
        </>
    );
};

export default PlanogramsList;
