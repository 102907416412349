import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Header, Title, Button, Filters, Search } from '@components';
import { Tooltip, useMediaQuery } from '@mui/material';
import styles from './Layout.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import AppSelector from '@redux/app/selector';
import AppAction from '@redux/app/action';
import { useTranslation } from 'react-i18next';
import IconFilter from '@images/svg/filter-icon.svg';
import IconClose from '@images/svg/close.svg';
import { FormProvider, useForm } from 'react-hook-form';
import { debounce } from '@tools/utils/functions';
import { IFilter } from '../Filters/Filters';

interface ILayout {
    title?: string | undefined;
    className?: string | undefined;
    renderHeader?: ({ defaultValues: {}, filters: {}, form: {} }) => ReactNode;
    renderSearch?: () => {};
    renderBaseFilters?: ({ defaultValues: {}, form: {} }) => ReactNode;
    renderData?: ({ defaultValues: {}, filters: {}, form: {} }) => ReactNode;
    renderFilters?: ({ defaultValues: {}, filters: {}, form: {} }) => {
        handleReset: () => void;
        filters: IFilter[];
        className: string;
    };
    getFilters?: (data: {}) => {};
    defaultValues?: {};
    type?: number;
}

const Layout: FC<ILayout> = ({
    title,
    renderHeader,
    renderSearch,
    renderBaseFilters,
    renderData,
    renderFilters,
    defaultValues = {},
    getFilters,
    className,
    type = 1,
}) => {
    const { t } = useTranslation('translation');
    const notMobile = useMediaQuery('(min-width:768px)');

    const dispatch = useDispatch();
    const isFilter = useSelector(AppSelector.filterSelector);

    useEffect(() => {
        if (!notMobile && isFilter) {
            handleToggleFilter();
        }
    }, [notMobile]);

    const handleToggleFilter = () => {
        dispatch(AppAction.filterToggle());
        localStorage.setItem('isFilter', String(!isFilter));
    };

    const form = useForm({ defaultValues });
    const { handleSubmit, watch } = form;

    const [filters, setFilters] = useState({});
    const onSubmit = useCallback((data) => {
        if (!getFilters) return;
        const filters = getFilters(data);
        setFilters(filters);

        return new Promise<void>((resolve) => {
            setTimeout(() => resolve(), 700);
        });
    }, []);

    useEffect(() => {
        const processSubmit = debounce(() => handleSubmit(onSubmit)(), 700);
        const subscription = watch(() => processSubmit());
        return () => subscription.unsubscribe();
    }, [watch, handleSubmit, onSubmit]);

    const titleFilter =
        notMobile &&
        `${isFilter ? t('common.close') : t('common.open')} ${t('common.filters').toLowerCase()}`;

    const renderSearchData = renderSearch && renderSearch();
    const renderFiltersData = renderFilters && renderFilters({ defaultValues, filters, form });
    const {
        handleReset,
        filters: filtersData,
        className: classNameFilters = '',
    } = renderFiltersData || {};

    const toggleBtn = (
        <Tooltip title={titleFilter || ''} placement="left" arrow>
            <div className={styles.toggleFilter}>
                <Button
                    onClick={handleToggleFilter}
                    className={styles.toggleFilterBtn}
                    variant="outlined"
                >
                    <img src={!isFilter ? IconFilter : IconClose} alt="" />
                </Button>
            </div>
        </Tooltip>
    );

    return (
        <div
            className={classNames(styles.layout, styles[`layoutType-${type}`], className, {
                [styles.isFilter]: isFilter && renderFilters,
            })}
        >
            <FormProvider {...form}>
                <Header className={classNames(styles.header, 'header')}>
                    {title && <Title className={styles.title}>{title}</Title>}
                    <div
                        className={classNames(styles.headerItems, 'headerItems', {
                            [styles.isFilter]: isFilter && renderFilters,
                        })}
                    >
                        {renderHeader && renderHeader({ defaultValues, filters, form })}
                        {type === 1 && renderFilters && toggleBtn}
                    </div>
                    {renderSearch && <Search {...renderSearchData} />}
                </Header>
                <div className={styles.wrapperDataFilters}>
                    {renderBaseFilters && renderBaseFilters({ defaultValues, form })}
                    {renderData && (
                        <div className={styles.data}>
                            {renderData({ defaultValues, filters, form })}
                            {type === 2 && renderFilters && toggleBtn}
                        </div>
                    )}
                    {renderFilters && (
                        <div className={styles.filters}>
                            {!notMobile && toggleBtn}
                            <Filters
                                className={classNameFilters}
                                defaultValues={defaultValues}
                                filters={filtersData}
                                form={form}
                                handleReset={handleReset}
                                isHeader={true}
                            />
                        </div>
                    )}
                </div>
            </FormProvider>
        </div>
    );
};

export default Layout;
