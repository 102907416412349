import React from 'react';
import { t } from 'i18next';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import { declOfNum } from '@tools/utils/string.utils';
import { updateFeed, deleteFeed } from '@apiFeature/feeds';
import {
    deleteRoutePoint,
    updateDateORUserRoutePoint,
    routePointsList,
    createRoutePoints,
    updateRoutePoints,
} from '@apiFeature/routePoints';
import { getRoutes, createRoutes } from '@apiFeature/routes';
import { VisitStatus } from '@/types/visits';
import { Filters, DatePicker, Select } from '@components';
import QuestionnairesInVisit from '../VisitScheduleCell/QuestionnairesInVisit/QuestionnairesInVisit';

const getDate = (date) => (date ? new Date(date).toISOString() : new Date().toISOString());

const handleStatusCompletedGroup = (props) => {
    const { statusCompletedData: res } = props;
    if (res && res?.length > 0) {
        for (const e of res) {
            handleStatusCompleted({
                ...props,
                status: e,
                isLastElement: res?.length === res.indexOf(e) + 1,
            });
        }
    }
};

const handleStatusCompleted = async ({
    status,
    enqueueSnackbar,
    setLoading,
    handleCloseAll,
    isLastElement = true,
}) => {
    const { id } = status || {};
    if (!id) {
        enqueueSnackbar(`${t('common.empty')} id!`, { variant: 'error' });
        return;
    }

    setLoading(true);
    try {
        await updateFeed({ visit_id: id, req: { status: VisitStatus.Completed } });

        if (isLastElement) {
            enqueueSnackbar(t('messages.statusSuccess'), { variant: 'success' });
            handleCloseAll(id);
        }
    } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error' });
    }
    setLoading(false);
};

const handleStatusNewGroup = (props) => {
    const { statusNewData: res } = props;
    if (res && res?.length > 0) {
        for (const e of res) {
            handleStatusNew({
                ...props,
                status: e,
                isLastElement: res?.length === res.indexOf(e) + 1,
            });
        }
    }
};

const handleStatusNew = async ({
    status,
    enqueueSnackbar,
    setLoading,
    handleCloseAll,
    isLastElement = true,
}) => {
    const { id } = status || {};
    if (!id) {
        enqueueSnackbar(`${t('common.empty')} id!`, { variant: 'error' });
        return;
    }

    setLoading(true);
    try {
        await deleteFeed(id);

        if (isLastElement) {
            enqueueSnackbar(t('messages.statusSuccess'), { variant: 'success' });
            handleCloseAll(id);
        }
    } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error' });
    }
    setLoading(false);
};

const handleChangeVisitDateOrUserGroup = (props) => {
    const { changeDateOrUserData: res } = props;
    if (res && res?.length > 0) {
        for (const e of res) {
            handleChangeVisitDateOrUser({
                ...props,
                status: e,
                isLastElement: res?.length === res.indexOf(e) + 1,
            });
        }
    }
};

const handleChangeVisitDateOrUser = async ({
    name,
    status,
    user_id,
    userId,
    to_date,
    date,
    enqueueSnackbar,
    setLoading,
    handleCloseAll,
    isLastElement = true,
}) => {
    const { route_point_id, userId: uId, date: d } = status || {};

    if (!route_point_id) {
        enqueueSnackbar(`${t('common.empty')} route_point_id!`, { variant: 'error' });
        return;
    }

    setLoading(true);
    try {
        await updateDateORUserRoutePoint({
            route_point_id,
            user_id: name === 'changeUser' ? user_id : userId || uId,
            to_date: name === 'changeDate' ? getDate(to_date) : getDate(date || d),
        });

        if (isLastElement) {
            enqueueSnackbar(t(`visits.${name}Success`), { variant: 'success' });
            handleCloseAll(route_point_id);
        }
    } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error' });
    }
    setLoading(false);
};

const handleScheduleJointTour = async ({
    status,
    enqueueSnackbar,
    setLoading,
    handleCloseAll,
    date,
    userId,
    project_outlet_id,
    audit_user_id,
}) => {
    const { route_point_id } = status || {};

    setLoading(true);
    try {
        let route_point_id_new;
        if (!route_point_id) {
            const visit_date_lower = fixTimezoneOffset(date, 'start');
            const visit_date_upper = fixTimezoneOffset(date, 'end');

            const { routes } =
                (await getRoutes({
                    visit_date_lower,
                    visit_date_upper,
                    user_id: userId,
                })) || {};

            let route_id = routes?.[0]?.id;
            if (!route_id) {
                const { route } =
                    (await createRoutes({
                        user_id: userId,
                        name: t('visits.newRoute'),
                        visit_date: visit_date_lower,
                    })) || {};

                route_id = route?.id;
            }
            const { route_points } =
                (await routePointsList({
                    project_outlet_id,
                    route_id,
                })) || {};

            let route_point_id = route_points?.[0]?.id;
            if (!route_point_id) {
                const { route_point } =
                    (await createRoutePoints({
                        route_id,
                        project_outlet_id,
                        is_scheduled: false,
                        audit_user_id,
                    })) || {};

                route_point_id_new = route_point?.id;
            } else {
                const { route_point } =
                    (await updateRoutePoints({
                        route_point_id,
                        project_outlet_id,
                        is_scheduled: false,
                        audit_user_id,
                    })) || {};

                route_point_id_new = route_point?.id;
            }
        } else {
            const { route_point } =
                (await updateRoutePoints({
                    route_point_id,
                    project_outlet_id,
                    is_scheduled: false,
                    audit_user_id,
                })) || {};

            route_point_id_new = route_point?.id;
        }

        enqueueSnackbar(t('visits.scheduleJointTourSuccess'), { variant: 'success' });
        handleCloseAll(route_point_id_new);
    } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error' });
    }
    setLoading(false);
};

const handleDeleteGroup = (props) => {
    const { deleteData: res } = props;
    if (res && res?.length > 0) {
        for (const e of res) {
            handleDelete({
                ...props,
                status: e,
                isLastElement: res?.length === res.indexOf(e) + 1,
            });
        }
    }
};

const handleDelete = async ({
    status,
    enqueueSnackbar,
    setLoading,
    handleCloseAll,
    selectedRoutes,
    isLastElement = true,
}) => {
    const { route_point_id } = status || {};

    if (!route_point_id) {
        enqueueSnackbar(`${t('common.empty')} route_point_id!`, { variant: 'error' });
        return;
    }

    setLoading(true);
    try {
        await deleteRoutePoint(route_point_id);

        if (isLastElement) {
            enqueueSnackbar(
                selectedRoutes?.length > 1
                    ? t('messages.visitsDeleteSuccess')
                    : t('messages.visitDeleteSuccess'),
                { variant: 'success' }
            );
            handleCloseAll(route_point_id);
        }
    } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error' });
    }
    setLoading(false);
};

const getCountText = (title, count) => (
    <>
        {title}{' '}
        <b>
            ({t('common.for')} {count}{' '}
            {declOfNum(count, [t('common.visit'), t('common.visits'), t('common.visits')])})
        </b>
    </>
);

export const getItemsNames = (props) => {
    const {
        selectedRoutes,
        to_date,
        user_id,
        usersOptions,
        onChange,
        status,
        date,
        project_outlet_id,
    } = props || {};

    let statusCompletedData = [];
    let statusNewData = [];
    let changeDateOrUserData = [];
    let deleteData = [];
    if ('selectedRoutes' in props) {
        statusCompletedData = selectedRoutes.filter((e) => e.statusType === VisitStatus.InProgress);
        statusNewData = selectedRoutes.filter(
            (e) =>
                e.statusType === VisitStatus.InProgress ||
                e.statusType === VisitStatus.Completed ||
                e.statusType === VisitStatus.ProblemReported
        );
        changeDateOrUserData = selectedRoutes.filter(
            (e) => e.statusType === VisitStatus.Unperformed || e.statusType === VisitStatus.New
        );
        deleteData = selectedRoutes.filter(
            (e) =>
                e.statusType === VisitStatus.Unperformed ||
                e.statusType === VisitStatus.ProblemReported
        );
    }

    return {
        statusCompleted: {
            title:
                selectedRoutes?.length > 1
                    ? getCountText(
                          t('visits.statusesCompletedConfirm'),
                          statusCompletedData?.length
                      )
                    : t('visits.statusCompletedConfirm'),
            action:
                'selectedRoutes' in props
                    ? handleStatusCompletedGroup.bind(this, { ...props, statusCompletedData })
                    : handleStatusCompleted.bind(this, props),
            confirmTitle: t('visits.setStatusCompleted'),
        },
        statusNew: {
            title:
                selectedRoutes?.length > 1
                    ? getCountText(t('visits.statusesNewConfirm'), statusNewData?.length)
                    : t('visits.statusNewConfirm'),
            action:
                'selectedRoutes' in props
                    ? handleStatusNewGroup.bind(this, { ...props, statusNewData })
                    : handleStatusNew.bind(this, props),
            confirmTitle: t('visits.setStatusNew'),
        },
        changeDate: {
            title:
                selectedRoutes?.length > 1
                    ? getCountText(t('visits.changeDate'), changeDateOrUserData?.length)
                    : t('visits.changeDate'),
            action:
                'selectedRoutes' in props
                    ? handleChangeVisitDateOrUserGroup.bind(this, {
                          name: 'changeDate',
                          ...props,
                          changeDateOrUserData,
                      })
                    : handleChangeVisitDateOrUser.bind(this, { name: 'changeDate', ...props }),
            content: (
                <Filters
                    filters={[
                        {
                            type: DatePicker,
                            name: 'to_date',
                            label: t('feed.visitDate'),
                            placeholder: t('feed.visitDate'),
                            dateFormat: 'dd.MM.yyyy',
                            value: to_date,
                            onChange,
                        },
                    ]}
                />
            ),
            confirmTitle: t('visits.changeDate'),
        },
        changeUser: {
            title:
                selectedRoutes?.length > 1
                    ? getCountText(t('visits.changeUser'), changeDateOrUserData?.length)
                    : t('visits.changeUser'),
            action:
                'selectedRoutes' in props
                    ? handleChangeVisitDateOrUserGroup.bind(this, {
                          name: 'changeUser',
                          ...props,
                          changeDateOrUserData,
                      })
                    : handleChangeVisitDateOrUser.bind(this, { name: 'changeUser', ...props }),
            content: (
                <Filters
                    filters={[
                        {
                            type: Select,
                            name: 'user_id',
                            label: t('common.user'),
                            placeholder: t('common.all'),
                            onChange,
                            value: user_id ? usersOptions?.find((e) => e.value === user_id) : null,
                            options: usersOptions,
                        },
                    ]}
                />
            ),
            confirmTitle: t('visits.changeUser'),
        },
        scheduleJointTour: {
            title: t('visits.scheduleJointTourConfirm'),
            action: handleScheduleJointTour.bind(this, props),
            confirmTitle: t('common.yes'),
        },
        delete: {
            title:
                props.selectedRoutes?.length > 1
                    ? getCountText(t('visits.deleteRoutesConfirm'), deleteData?.length)
                    : t('visits.deleteRouteConfirm'),
            action:
                'selectedRoutes' in props
                    ? handleDeleteGroup.bind(this, { ...props, deleteData })
                    : handleDelete.bind(this, props),
            confirmTitle: t('buttons.delete'),
        },
        questionnaireList: {
            title: t('questionnaires.shownList'),
            action: () => {},
            content: (
                <QuestionnairesInVisit
                    visit_id={status?.id}
                    route_point_id={status?.route_point_id}
                    date={date}
                    project_outlet_id={project_outlet_id}
                />
            ),
        },
    };
};
