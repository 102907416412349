import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './SettingsData.module.scss';
import { TableGrid, Loader } from '@components';
import { fetchUserList } from '@apiFeature/user';
import { extractErrorMessage } from '@tools/utils/functions';
import { useSnackbar } from 'notistack';
import { fetchUserSettings, fetchSettingsCategories, updateSettings } from '@apiFeature/settings';
import SettingsSelectors from '@redux/settings/selectors';
import { SettingsThunks } from '@redux/settings/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ActionBtns from '../ActionBtns/ActionBtns';
import { DialogForSettings } from '../DialogForSettings/DialogForSettings';
import { RenderCellInput } from '../RenderEditCell/RenderEditCell';
import classNames from 'classnames';
import { UserSettingItem } from '@apiFeature/settings/types';

const limit = 10;
export const SettingsData: FC = ({ defaultValues, filters, form }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');
    const project_id = filters?.project_id || defaultValues?.project_id;
    const paginationModelDefault = { page: 0, pageSize: limit };
    const [columns, setColumns] = useState<any>([]);
    const [rows, setRows] = useState([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isUpdateLoading, setUpdateLoading] = useState<boolean>(false);
    const [usersData, setUsersData] = useState<any | null>(null);
    const [settingsData, setSettingsData] = useState<any | null>(null);
    const [categories, setCategories] = useState<any>([]);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const { items = [], total = 0, offset } = usersData || {};
    const [initFormValues, setInitFormValues] = useState(null);
    const [newData, setNewData] = useState(null);

    const { watch, setValue, handleSubmit } = form;
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isBtnsVisible, setIsButtonsVisible] = useState(false);
    const [changedCells, setChangedCells] = useState({});

    const { role, name } = filters || {};

    const dispatch = useDispatch();
    const { settingsOptions } = useSelector(SettingsSelectors.settingsState);

    const getSettingsOptions = useCallback(
        async (setting_id) => {
            const currentSettingOptions = settingsOptions?.[setting_id];

            if (!currentSettingOptions && !currentSettingOptions?.length) {
                return dispatch(
                    SettingsThunks.getSettingsOptions({
                        setting_id,
                    })
                );
            }
        },
        [rows]
    );

    const getUsersList = useCallback(
        async (offset = 0) => {
            setLoading(true);
            fetchUserList({
                ...(role && { roles: [role] }),
                ...(name && { name }),
                pagination: {
                    limit,
                    offset,
                },
            })
                .then((res) => {
                    setUsersData(res);
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [role, name]
    );

    const getSettingsCategories = useCallback(() => {
        if (!categories?.length) {
            setLoading(true);
            fetchSettingsCategories()
                .then((res) => {
                    const { categories: catInit } = res || {};
                    if (catInit?.length) {
                        const resultCategories = catInit.filter(
                            (c) => c.setting_name !== null && c.setting_code !== 'test'
                        );
                        setCategories(resultCategories);
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    const getSettingsByUserId = useCallback(
        async (users) => {
            if (users?.length) {
                const ids = users.map((user) => user.id);
                const settingsPromises = ids.map((id) => {
                    return fetchUserSettings({
                        user_id: id,
                        project_id,
                        pagination: { limit: 50 },
                    })
                        .then((settingsResponse) => {
                            const { user_settings } = settingsResponse || {};
                            const { items } = user_settings || {};
                            return items;
                        })
                        .catch((error) => {
                            enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                        });
                });
                if (settingsPromises?.length) {
                    try {
                        const fetchedSettings = await Promise.all(settingsPromises);
                        if (fetchedSettings?.length) {
                            const settingsResults = fetchedSettings
                                .flat()
                                .filter((s) => s !== undefined);
                            const transformedData = settingsResults.reduce((acc, currentValue) => {
                                const { user_id, setting_id, setting_value, setting_option_id } =
                                    currentValue;
                                if (!acc?.[user_id]) {
                                    acc[user_id] = {};
                                }
                                acc[user_id][setting_id] = {
                                    setting_value,
                                    setting_option_id,
                                };
                                return acc;
                            }, {});
                            setValue('users', transformedData);
                            setInitFormValues(transformedData);
                            return settingsResults;
                        }
                    } catch (error) {
                        enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                    }
                }
            }
        },
        [items]
    );

    useEffect(() => {
        getSettingsCategories();
    }, []);

    useEffect(() => {
        getUsersList(paginationModel?.page * limit);
    }, [paginationModel]);

    useEffect(() => {
        setPaginationModel(paginationModelDefault);
    }, [project_id, newData, role, name]);

    const handleSetValuesChanges = (currentValue, initValue, itemId, settingId) => {
        setChangedCells((prevChanges) => {
            if (currentValue !== initValue) {
                return {
                    ...prevChanges,
                    [itemId]: {
                        ...prevChanges[itemId],
                        [settingId]: true,
                    },
                };
            } else {
                const newChanges = { ...prevChanges };
                if (newChanges[itemId]) {
                    delete newChanges[itemId][settingId];
                    if (Object.keys(newChanges[itemId])?.length === 0) {
                        delete newChanges[itemId];
                    }
                }
                return newChanges;
            }
        });
    };

    useEffect(() => {
        if (items?.length && initFormValues) {
            const columnsList = items.map((user) => {
                const { first_name, last_name, id: userId, patronymic_name, roles, phone } = user || {};
                return {
                    field: userId,
                    headerName: `${last_name} ${first_name} ${patronymic_name}`,
                    cellClassName: (params) => {
                        const { setting_id } = params.row;
                        return classNames(styles.cellCustom, {
                            [styles.editedCell]: Boolean(changedCells?.[userId]?.[setting_id]),
                        });
                    },
                    renderHeader: () => (
                        <div className={styles.headerCell}>
                            <div>{last_name}</div>
                            <div>{first_name}</div>
                            <div>{phone}</div>
                            <div className={styles.rolesContainer}>
                                {roles?.length > 0 &&
                                    roles.map((role, index) => (
                                        <span key={index} className={styles.roleBadge}>
                                            {role?.name}
                                        </span>
                                    ))}
                            </div>
                        </div>
                    ),
                    renderCell: (params) => {
                        const { setting_id, setting_kind } = params.row;
                        const currentValue = watch(`users[${userId}][${setting_id}]`);
                        const initValue = initFormValues?.[userId]?.[setting_id]?.setting_value;
                        const api = params.api;
                        const rowIndex = api.getRowIndexRelativeToVisibleRows(params.row.id) + 1;
                        const totalRows = rows.length;
                        const argsForRenderCell = {
                            setting: params.row,
                            currentValue,
                            registerStrValue: `users[${userId}][${setting_id}].setting_value`,
                            registerStrOptionId: `users[${userId}][${setting_id}].setting_option_id`,
                            setValue,
                            settingsOptions,
                            setting_kind,
                            getSettingsOptions,
                            setIsButtonsVisible,
                            rowIndex,
                            totalRows,
                            handleSetValuesChanges,
                            initValue,
                            itemId: userId,
                        };
                        return <RenderCellInput args={argsForRenderCell} />;
                    },
                    width: 200,
                    sortable: false,
                };
            });
            setColumns([
                {
                    field: 'category_name',
                    headerName: t('settings.category'),
                    width: 200,
                },
                {
                    field: 'setting_name',
                    headerName: t('settings.setting'),
                    width: 200,
                    sortable: false,
                },
                ...columnsList,
            ]);
        }
    }, [items, settingsOptions, initFormValues, changedCells]);

    useEffect(() => {
        getSettingsByUserId(items)
            .then((res) => {
                setSettingsData(res);
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            });
    }, [items]);

    useEffect(() => {
        if (categories?.length) {
            const resultRows = categories.map((c) => {
                const { category_name, setting_id } = c || {};
                let row = {
                    category_name,
                    ...c,
                };
                if (settingsData?.length > 0) {
                    const settingsWithCurrentId = settingsData.filter(
                        (s) => s.setting_id === setting_id
                    );
                    settingsWithCurrentId?.forEach((s) => {
                        row[s.user_id] = s;
                    });
                }
                return row;
            });
            setRows(resultRows);
        }
    }, [settingsData, categories]);

    useEffect(() => {
        if (rows?.length) {
            rows.forEach((r) => {
                const { setting_kind, setting_id } = r || {};
                if (setting_kind === 'RIGHTS') {
                    getSettingsOptions(setting_id);
                }
            });
        }
    }, [rows]);

    useEffect(() => {
        if (!Object.keys(changedCells)?.length) {
            setIsButtonsVisible(false);
        }
    }, [changedCells]);

    const getRowId = (row) => {
        return row?.setting_id;
    };

    const handleCloseDialog = () => {
        setIsOpenDialog(false);
        setIsButtonsVisible(false);
        setChangedCells({});
        setValue('users', initFormValues);
    };

    const onSubmit = (data) => {
        const { users } = data || {};
        let payload: UserSettingItem[] = [];
        if (users) {
            const user_ids = Object.keys(users);
            if (user_ids?.length > 0) {
                user_ids.forEach((uId) => {
                    const settings = Object.keys(data.users[uId]);
                    if (settings?.length > 0) {
                        settings.forEach((sId) => {
                            const { setting_option_id } = data.users[uId][sId] || {};
                            const { setting_option_id: initValue } =
                                initFormValues?.[uId]?.[sId] || {};
                            if (setting_option_id && setting_option_id !== initValue) {
                                payload.push({
                                    setting_id: sId,
                                    user_id: uId,
                                    setting_option_id,
                                    ...(project_id && { project_id }),
                                });
                            }
                        });
                    }
                });
                if (payload?.length > 0) {
                    setUpdateLoading(true);
                    updateSettings(payload)
                        .then((res) => {
                            setNewData(res);
                            setInitFormValues(data.users);
                        })
                        .catch((error) => {
                            enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                        })
                        .finally(() => {
                            setUpdateLoading(false);
                            setIsOpenDialog(false);
                            setIsButtonsVisible(false);
                            setChangedCells({});
                        });
                }
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {isUpdateLoading && <Loader />}
            <TableGrid
                rows={rows}
                columns={columns}
                rowCount={total}
                loading={isLoading}
                pageSizeOptions={[limit]}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                paginationModel={offset === 0 ? paginationModelDefault : paginationModel}
                rowHeight={80}
                columnHeaderHeight={200}
                getRowHeight={() => 'auto'}
                getRowId={getRowId}
                className={styles.tableGrid}
                disableRowSelectionOnClick
            />
            {isBtnsVisible && (
                <ActionBtns handleCancel={handleCloseDialog} setIsOpenDialog={setIsOpenDialog} />
            )}
            <DialogForSettings isOpenDialog={isOpenDialog} handleCloseDialog={handleCloseDialog} />
        </form>
    );
};
