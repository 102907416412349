import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './TeamDetails.module.scss';
import {
    Header,
    Title,
    BackLink,
    TextField,
    Select,
    DatePicker,
    Button,
    Loader,
    TableGrid,
} from '@components';
import { Tooltip } from '@mui/material';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { Book } from '@/navigation/Book';
import { useNavigate, useParams } from 'react-router-dom';
import {
    getUserById,
    getUsrProj,
    createUserV2,
    updateUserV2,
    addRoles,
    deleteRoles,
    fetchSetPass,
    userAssignProject,
    unlinkUserProject,
    fetchUserList,
} from '@apiFeature/user';
import { fetchTagsByEntityId, fetchTagCreate, fetchTagDelete } from '@apiFeature/tags';
import { TagCreateRequestType, Tags } from '@apiFeature/tags/types';
import { useSnackbar } from 'notistack';
import { User } from '@apiFeature/types';
import { Controller, useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { object, string, array, ref } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { statusOptions, genderOptions } from '@constants';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { Project } from '@types/projects';
import { UserProjectItem } from './UserPojectItem/UserProjectItem';
import ActionsBtns from './ActionsBtns/ActionsBtns';
import moment from 'moment';
import { RolesType } from '@apiFeature/roles/types';
import { useRoles } from '@tools/hooks/useRoles';
import { extractErrorMessage } from '@tools/utils/functions';
import { getFullUserName } from '@tools/utils/users';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { removeCharacters } from '@tools/utils/string.utils';
import classNames from 'classnames';
import { v4 as uniqid } from 'uuid';

const getTag = (tags, entity_type, project_id) =>
    tags.filter((e) => e.entity_type === entity_type && e.project_id === project_id) || [];

export const TeamDetails: FC = ({ isUser = false }) => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const { roles } = useSelector(FiltersSelectors.filtersState);
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const authRoles = useSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { isAdmin, isVisitReport, isTestUser } = useRoles();
    const isAvailable = isAdmin || isVisitReport || isTestUser;

    const [user, setUser] = useState<User | null>(null);
    const [userProjects, setUserProjects] = useState<Project[]>([]);
    const [initRoles, setInitRoles] = useState<RolesType[]>([]);
    const [initTags, setInitTags] = useState<Tags[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingUser, setLoadingUser] = useState<boolean>(false);

    const defaultErrorText = t('messages.fieldRequired');
    const invalidPhone = t('messages.invalidPhone');
    const invalidEmail = t('messages.invalidEmail');
    const mismatchPassword = t('catalog.team.mismatchPassword');
    const minPassword = t('catalog.team.min');
    const invalidSnils = t('filledVisit.invalidSnils');
    const invalidDoublesProjectsForm = t('messages.invalidDoublesProjectsForm');
    const validationSchema = object({
        last_name: string().required(defaultErrorText),
        first_name: string().required(defaultErrorText),
        patronymic_name: string().nullable(),
        phone: string()
            .test('phone', invalidPhone, (phone) => {
                const value = removeCharacters(phone);
                return !(value && value?.length > 0 && value?.length < 12);
            })
            .required(defaultErrorText),
        date_of_birth: string().nullable(),
        email: string().email(invalidEmail).nullable(),
        snils: string()
            .nullable()
            .test('snils', invalidSnils, (snils) => {
                const value = removeCharacters(snils);
                return !(value && value?.length > 0 && value?.length < 11);
            }),
        is_active: string().nullable(),
        gender: string().nullable(),
        roles: array().nullable(),
        userProjects: array()
            .test('userProjects', invalidDoublesProjectsForm, function (list) {
                const projects = list?.map((e) => e.project);
                return projects?.length === new Set(projects).size;
            })
            .of(
                object().shape({
                    project: string().required(defaultErrorText),
                })
            ),
        password: string()
            .when('userId', (userId, schema) =>
                !userId?.[0] ? schema.required(defaultErrorText) : schema
            )
            .test(
                'password',
                minPassword,
                (password) => !(password && password?.length > 0 && password?.length < 6)
            ),
        confirmPassword: string().when('password', {
            is: (password) => Boolean(password),
            then: (schema) =>
                schema.oneOf([ref('password')], mismatchPassword).required(defaultErrorText),
        }),
    });
    const form = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) });
    const { control, reset, setValue, handleSubmit, watch, trigger, formState, getFieldState } =
        form;
    const { error } = getFieldState('userProjects', formState);
    const { defaultValues } = formState;
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'userProjects',
    });

    const currentValues = watch();
    const {
        isEdit,
        roles: roleValues,
        is_active: status,
        phone,
        gender,
        date_of_birth,
        email,
        snils,
    } = currentValues || {};

    const getData = () => {
        setLoadingUser(true);
        getUserById(userId)
            ?.then((result) => {
                const { user, roles } = result || {};
                const rolesIds = roles ? roles.map((role) => role?.id) : [];
                setInitRoles(rolesIds);
                setUser(user);

                const {
                    last_name,
                    first_name,
                    patronymic_name,
                    phone,
                    snils,
                    status,
                    date_of_birth,
                    sex,
                    email,
                } = user || {};

                const defaultValues = {
                    last_name,
                    first_name,
                    patronymic_name,
                    phone,
                    snils,
                    is_active: status,
                    date_of_birth,
                    gender: sex,
                    email,
                    roles: rolesIds,
                    password: '',
                    isEdit: false,
                    userId,
                };
                reset({ ...defaultValues });
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            })
            .finally(() => {
                setLoadingUser(false);
            });

        setLoading(true);
        getUsrProj({
            user_id: userId,
        })
            .then(async ({ user_projects }) => {
                let userProjects: any = [];
                if (user_projects.length) {
                    const { tags } = (await fetchTagsByEntityId(userId)) || {};
                    if (tags?.length > 0) setInitTags(tags);

                    userProjects = user_projects.map((usrProject) => {
                        const { id, project_id, manager_id } = usrProject || {};
                        const project_name = projectsList.find(
                            (ee) => ee.id === project_id
                        )?.full_name;

                        const branches = getTag(tags, 'branches', project_id);
                        const territory = getTag(tags, 'territory', project_id);
                        const project_retails = getTag(tags, 'project_retails', project_id);
                        const questionnaires = getTag(tags, 'questionnaires', project_id);

                        return {
                            id_item: id,
                            project: project_id,
                            project_name,
                            manager: manager_id,
                            branches: branches.map((e) => e.tag_id),
                            branches_name: branches.map((e) => e.tag_name).join(', '),
                            branches_default: branches,
                            territory: territory.map((e) => e.tag_id),
                            territory_name: territory.map((e) => e.tag_name).join(', '),
                            territory_default: territory,
                            project_retails: project_retails.map((e) => e.tag_id),
                            project_retails_name: project_retails.map((e) => e.tag_name).join(', '),
                            project_retails_default: project_retails,
                            questionnaires: questionnaires.map((e) => e.tag_id),
                            questionnaires_name: questionnaires.map((e) => e.tag_name).join(', '),
                            questionnaires_default: questionnaires,
                        };
                    });

                    const mIds = userProjects.map((e) => e.manager);
                    const manager_ids = [...new Set(mIds)].filter((e) => e);
                    if (manager_ids.length > 0) {
                        await fetchUserList({ ids: manager_ids })
                            .then(({ items }) => {
                                userProjects = userProjects.map((e) => {
                                    const res = items.find((ee) => ee.id === e.manager);
                                    return {
                                        ...e,
                                        manager_name: getFullUserName(res) ?? '-',
                                    };
                                });
                            })
                            .catch((error) => {
                                enqueueSnackbar(extractErrorMessage(error), {
                                    variant: 'error',
                                });
                            });
                    }
                    setUserProjects(userProjects);
                }

                setValue('userProjects', userProjects);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (userId && projectsList.length > 0) {
            getData();
        }
    }, [userId, projectsList]);

    const getRoles = useCallback(() => {
        if (!roles.length) {
            dispatch(FiltersThunks.getRolesThunk({}));
        }
    }, [dispatch, roles]);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        getRoles();
        getProjects();
    }, [userId]);

    useEffect(() => {
        if (error?.message) {
            enqueueSnackbar(error?.message, {
                variant: 'error',
            });
        }
    }, [error?.message]);

    let rolesData = [...roles];
    if (!isAdmin)
        rolesData = rolesData?.filter(
            (role) => !['stompy', 'cunning_merchandiser'].includes(role.sys_name)
        );
    const rolesOptions = rolesData?.map((role) => ({
        label: role.name,
        value: role.id,
    }));

    const rolesValue = roleValues
        ? rolesOptions?.filter((e) => roleValues.includes(e.value))
        : null;

    const projectOptions = projectsList?.map(({ full_name, id }) => ({
        label: full_name,
        value: id,
    }));

    const filteredStatusOptions = statusOptions.filter((option) => option?.value !== 'banned');

    const onError = () => {
        if (!formState.isValid) {
            enqueueSnackbar(error?.message || t('messages.invalidForm'), {
                variant: 'error',
            });
        }
    };

    const onSubmit = (data) => {
        const { isDirty, dirtyFields } = formState;
        if (isDirty || Object.keys(dirtyFields).length) {
            setLoadingUser(true);
            setLoading(true);
            const {
                last_name,
                first_name,
                patronymic_name,
                phone,
                is_active,
                snils,
                roles,
                date_of_birth,
                gender,
                email,
                password,
                userProjects: editUserProjects,
            } = data || {};
            const request = {
                last_name,
                first_name,
                patronymic_name,
                phone: removeCharacters(phone),
                snils: removeCharacters(snils),
                email,
                date_of_birth: date_of_birth ? moment(date_of_birth).format('YYYY-MM-DD') : null,
                status: is_active,
                sex: gender,
            };

            const userV2 = userId ? updateUserV2(userId, request) : createUserV2(request);
            const userMessage = userId
                ? t('catalog.team.userUpdated')
                : t('catalog.team.userCreated');

            userV2
                .then(async (result) => {
                    enqueueSnackbar(userMessage, { variant: 'success' });

                    const { user } = result || {};
                    const user_id = user?.id;

                    const addedRoles = roles?.filter((role) => !initRoles?.includes(role));
                    const deletedRoles = initRoles?.filter((role) => !roles?.includes(role));

                    if (addedRoles?.length > 0) {
                        const rolesPayload = addedRoles.map((id) => ({
                            role_id: id,
                        }));

                        await addRoles(user_id, { roles: rolesPayload })
                            .then(() => {
                                userId &&
                                    enqueueSnackbar(t('messages.rolesUpdated'), {
                                        variant: 'success',
                                    });
                            })
                            .catch((error) => {
                                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                            });
                    }
                    if (deletedRoles?.length > 0) {
                        const rolesPayload = deletedRoles.map((id) => ({
                            role_id: id,
                        }));

                        await deleteRoles(user_id, { roles: rolesPayload })
                            .then(() => {
                                userId &&
                                    enqueueSnackbar(t('messages.rolesUpdated'), {
                                        variant: 'success',
                                    });
                            })
                            .catch((error) => {
                                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                            });
                    }
                    if (password) {
                        await fetchSetPass({
                            user_id,
                            request: {
                                password,
                            },
                        })
                            .then(() => {
                                userId &&
                                    enqueueSnackbar(t('messages.passwordChanged'), {
                                        variant: 'success',
                                    });
                            })
                            .catch((error) => {
                                enqueueSnackbar(extractErrorMessage(error), {
                                    variant: 'error',
                                });
                            });
                    }
                    const addedUserProjects = editUserProjects?.map((addedItem) => ({
                        project_id: addedItem.project,
                        manager_id: addedItem.manager || undefined,
                    }));
                    const deletedUserProjects = userProjects
                        ?.filter(
                            (initItem) =>
                                !editUserProjects.find(
                                    (editItem) => initItem.project === editItem.project
                                )
                        )
                        .map((deletedItem) => ({
                            user_id,
                            project_id: deletedItem.project,
                        }));
                    if (addedUserProjects?.length > 0) {
                        await userAssignProject({
                            user_id,
                            request: addedUserProjects,
                        })
                            .then(() => {
                                userId &&
                                    enqueueSnackbar(t('messages.userProjectChanged'), {
                                        variant: 'success',
                                    });
                            })
                            .catch((error) => {
                                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                            });
                    }
                    if (deletedUserProjects?.length > 0) {
                        await unlinkUserProject(deletedUserProjects)
                            .then(() => {
                                userId &&
                                    enqueueSnackbar(t('messages.userProjectChanged'), {
                                        variant: 'success',
                                    });
                            })
                            .catch((error) => {
                                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                            });
                    }

                    if (initTags?.length > 0) {
                        await Promise.all(
                            initTags.map(async (e) => await fetchTagDelete(e.conection_id))
                        ).catch((error) => {
                            enqueueSnackbar(extractErrorMessage(error), {
                                variant: 'error',
                            });
                        });
                    }

                    const tags: TagCreateRequestType[] = [];
                    editUserProjects?.forEach((e) => {
                        const item = { taggable_id: user_id, taggable_type: 'users' };
                        if (e.branches)
                            e.branches.forEach((ee) => tags.push({ ...item, tag_id: ee }));
                        if (e.territory)
                            e.territory.forEach((ee) => tags.push({ ...item, tag_id: ee }));
                        if (e.project_retails)
                            e.project_retails.forEach((ee) => tags.push({ ...item, tag_id: ee }));
                        if (e.questionnaires)
                            e.questionnaires.forEach((ee) => tags.push({ ...item, tag_id: ee }));
                    });
                    if (tags?.length > 0) {
                        await Promise.all(tags.map(async (e) => await fetchTagCreate(e))).catch(
                            (error) => {
                                enqueueSnackbar(extractErrorMessage(error), {
                                    variant: 'error',
                                });
                            }
                        );
                    }

                    if (userId) {
                        setValue('isEdit', false);
                        getData();
                    } else {
                        navigate(`${Book.catalog[isUser ? 'users' : 'team']}/${user_id}`);
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                })
                .finally(() => {
                    setLoadingUser(false);
                    setLoading(false);
                });
        } else {
            enqueueSnackbar(t('messages.notChanged'), { variant: 'info' });
        }
    };

    let items;
    if (!isEdit) {
        items = {
            [t('common.phone')]: { value: phone ?? '-', isVisible: true },
            [t('catalog.team.sex')]: {
                value: genderOptions?.find((e) => e.value === gender)?.label ?? '-',
                isVisible: true,
            },
            [t('catalog.team.roles')]: {
                value:
                    rolesValue
                        ?.map((e) => e.label)
                        .filter(Boolean)
                        .join(', ') ?? '-',
                isVisible: true,
            },
            [t('catalog.team.birthDate')]: {
                value: date_of_birth ? moment(date_of_birth).format('YYYY-MM-DD') : '-',
                isVisible: true,
            },
            [t('catalog.team.email')]: { value: email ?? '-', isVisible: true },
            [t('reports.reports.snils')]: { value: snils ?? '-', isVisible: true },
        };
    }

    const columns = [
        {
            field: 'project_name',
            headerName: t('common.project'),
            minWidth: 250,
            sortable: false,
        },
        {
            field: 'manager_name',
            headerName: t('common.manager'),
            minWidth: 350,
            sortable: false,
        },
        {
            field: 'branches_name',
            headerName: t('feed.branch'),
            minWidth: 250,
            sortable: false,
        },
        {
            field: 'territory_name',
            headerName: t('common.territory'),
            minWidth: 250,
            sortable: false,
        },
        {
            field: 'project_retails_name',
            headerName: t('common.retail'),
            minWidth: 250,
            sortable: false,
        },
        {
            field: 'questionnaires_name',
            headerName: t('importJournal.questionnaire'),
            minWidth: 250,
            sortable: false,
        },
    ];

    return (
        <div className={styles.main}>
            <Header type="inner">
                <Title>
                    <BackLink link={Book.catalog[isUser ? 'users' : 'team']} />
                    {userId ? (
                        <div className={styles.head}>
                            <div className={styles.title}>{getFullUserName(user)}</div>
                            <div
                                className={
                                    status === 'active'
                                        ? styles.activeStatus
                                        : styles.nonActiveStatus
                                }
                            >
                                {status === 'active'
                                    ? t('statuses.active')
                                    : t('statuses.inactive')}
                            </div>
                        </div>
                    ) : (
                        t('catalog.team.newUser')
                    )}
                </Title>
            </Header>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                {!isEdit && userId ? (
                    <div className={styles.formWrapper}>
                        <div className={styles.userInfo}>
                            {loadingUser && <Loader isPortal={false} />}
                            <ListMapper
                                items={permissionCheck(authRoles, items)}
                                className={styles.serializedFeedItem}
                            />
                        </div>
                        <div className={styles.projectsInfo}>
                            {loading && <Loader isPortal={false} />}
                            {userProjects && userProjects?.length > 0 && (
                                <>
                                    <Title className={styles.title}>
                                        {t('catalog.projects.title')}
                                    </Title>
                                    <TableGrid
                                        rows={userProjects}
                                        columns={columns}
                                        className={styles.tableGrid}
                                        getRowHeight={() => 'auto'}
                                        getRowId={(row) => row.id_item}
                                        disableRowSelectionOnClick
                                        hideFooterPagination
                                    />
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={styles.formWrapper}>
                        <div className={styles.userInfo}>
                            {loadingUser && <Loader isPortal={false} />}
                            <div className={styles.formItems}>
                                <Tooltip
                                    placement="top"
                                    title={
                                        defaultValues?.last_name && !isAdmin
                                            ? t('catalog.team.tooltip')
                                            : ''
                                    }
                                >
                                    <div
                                        className={classNames(
                                            styles.formItem,
                                            styles.textFieldWrapper
                                        )}
                                    >
                                        <Controller
                                            name="last_name"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    label={t('catalog.team.lastname')}
                                                    onChange={onChange}
                                                    value={value || ''}
                                                    variant="outlined"
                                                    error={error}
                                                    disabled={defaultValues?.last_name && !isAdmin}
                                                />
                                            )}
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    title={
                                        defaultValues?.first_name && !isAdmin
                                            ? t('catalog.team.tooltip')
                                            : ''
                                    }
                                >
                                    <div
                                        className={classNames(
                                            styles.formItem,
                                            styles.textFieldWrapper
                                        )}
                                    >
                                        <Controller
                                            name="first_name"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    label={t('catalog.team.firstname')}
                                                    onChange={onChange}
                                                    value={value || ''}
                                                    variant="outlined"
                                                    error={error}
                                                    disabled={defaultValues?.first_name && !isAdmin}
                                                />
                                            )}
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    title={
                                        defaultValues?.patronymic_name && !isAdmin
                                            ? t('catalog.team.tooltip')
                                            : ''
                                    }
                                >
                                    <div
                                        className={classNames(
                                            styles.formItem,
                                            styles.textFieldWrapper
                                        )}
                                    >
                                        <Controller
                                            name="patronymic_name"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    label={t('catalog.team.patronymic')}
                                                    onChange={onChange}
                                                    value={value || ''}
                                                    variant="outlined"
                                                    error={error}
                                                    disabled={
                                                        defaultValues?.patronymic_name && !isAdmin
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    title={
                                        defaultValues?.phone && !isAdmin
                                            ? t('catalog.team.tooltip')
                                            : ''
                                    }
                                >
                                    <div
                                        className={classNames(
                                            styles.formItem,
                                            styles.textFieldWrapper
                                        )}
                                    >
                                        <Controller
                                            name="phone"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    label={t('common.phone')}
                                                    onChange={onChange}
                                                    value={value || ''}
                                                    variant="outlined"
                                                    error={error}
                                                    disabled={defaultValues?.phone && !isAdmin}
                                                    placeholder="+7(***)***-**-**"
                                                    mask={[
                                                        '+',
                                                        /\d/,
                                                        ' ',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        ' ',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '-',
                                                        /\d/,
                                                        /\d/,
                                                        '-',
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                />
                                            )}
                                        />
                                    </div>
                                </Tooltip>
                                <div className={styles.formItem}>
                                    <Controller
                                        name="is_active"
                                        control={control}
                                        render={({
                                            field: { name, value, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Select
                                                id={name}
                                                name={name}
                                                className={classNames({
                                                    [styles.active]: status === 'active',
                                                    [styles.inactive]: status === 'inactive',
                                                })}
                                                inputRef={ref}
                                                label={t('common.status')}
                                                onChange={(e) => {
                                                    setValue(name, e.value, {
                                                        shouldDirty: true,
                                                        shouldValidate: true,
                                                    });
                                                }}
                                                value={filteredStatusOptions?.find(
                                                    (e) => e.value === value
                                                )}
                                                options={filteredStatusOptions}
                                                error={error}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={styles.formItem}>
                                    <Controller
                                        name="gender"
                                        control={control}
                                        render={({
                                            field: { name, value, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Select
                                                id={name}
                                                name={name}
                                                inputRef={ref}
                                                label={t('catalog.team.sex')}
                                                onChange={(e) => {
                                                    setValue(name, e.value, {
                                                        shouldDirty: true,
                                                        shouldValidate: true,
                                                    });
                                                }}
                                                value={genderOptions?.find(
                                                    (e) => e.value === value
                                                )}
                                                options={genderOptions}
                                                error={error}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classNames(styles.formItem, styles.roleSelect)}>
                                    <Controller
                                        name="roles"
                                        control={control}
                                        render={({
                                            field: { name, value, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Select
                                                id={name}
                                                name={name}
                                                inputRef={ref}
                                                label={t('catalog.team.roles')}
                                                onChange={(e) => {
                                                    let value = e?.value;
                                                    if (e && Array.isArray(e)) {
                                                        value =
                                                            e.length > 0
                                                                ? e.map((item) => item.value)
                                                                : null;
                                                    }
                                                    setValue(name, value, {
                                                        shouldDirty: true,
                                                        shouldValidate: true,
                                                    });
                                                }}
                                                value={rolesValue}
                                                options={rolesOptions}
                                                isMulti={true}
                                                error={error}
                                            />
                                        )}
                                    />
                                </div>
                                <div
                                    className={classNames(
                                        styles.formItem,
                                        styles.datePickerWrapper
                                    )}
                                >
                                    <Controller
                                        name="date_of_birth"
                                        control={control}
                                        render={({
                                            field: { name, value, ref, onChange },
                                            fieldState: { error },
                                        }) => (
                                            <DatePicker
                                                id={name}
                                                name={name}
                                                inputRef={ref}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    trigger('date_of_birth');
                                                }}
                                                label={t('catalog.team.birthDate')}
                                                value={value}
                                                enable={[]}
                                                dateFormat="dd.MM.yyyy"
                                                error={error}
                                            />
                                        )}
                                    />
                                </div>
                                <div
                                    className={classNames(styles.formItem, styles.textFieldWrapper)}
                                >
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                label={t('catalog.team.email')}
                                                onChange={onChange}
                                                value={value || ''}
                                                variant="outlined"
                                                error={error}
                                            />
                                        )}
                                    />
                                </div>
                                <Tooltip
                                    placement="top"
                                    title={
                                        defaultValues?.snils && !isAvailable
                                            ? t('catalog.team.tooltip')
                                            : ''
                                    }
                                >
                                    <div
                                        className={classNames(
                                            styles.formItem,
                                            styles.textFieldWrapper
                                        )}
                                    >
                                        <Controller
                                            name="snils"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <TextField
                                                    label={t('reports.reports.snils')}
                                                    onChange={onChange}
                                                    value={value || ''}
                                                    variant="outlined"
                                                    error={error}
                                                    disabled={defaultValues?.snils && !isAvailable}
                                                    placeholder="***-***-*** **"
                                                    mask={[
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '-',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '-',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        ' ',
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                />
                                            )}
                                        />
                                    </div>
                                </Tooltip>
                                <div
                                    className={classNames(styles.formItem, styles.textFieldWrapper)}
                                >
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                label={t('catalog.team.newPassword')}
                                                onChange={onChange}
                                                value={value || ''}
                                                variant="outlined"
                                                error={error}
                                                type="password"
                                            />
                                        )}
                                    />
                                </div>
                                <div
                                    className={classNames(styles.formItem, styles.textFieldWrapper)}
                                >
                                    <Controller
                                        name="confirmPassword"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                label={t('catalog.team.repeatPassword')}
                                                onChange={onChange}
                                                value={value || ''}
                                                variant="outlined"
                                                error={error}
                                                type="password"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.projectsInfo}>
                            {loading && <Loader isPortal={false} />}
                            <Title className={styles.title}>{t('catalog.projects.title')}</Title>
                            <FormProvider {...form}>
                                {fields.map((item, index) => (
                                    <UserProjectItem
                                        key={item.id_item}
                                        registerStr={`userProjects[${index}]`}
                                        projectOptions={projectOptions}
                                        removeProject={remove}
                                        idx={index}
                                        userProjects={userProjects}
                                        id_item={item.id_item}
                                        isAdmin={isAdmin}
                                        isEdit={isEdit}
                                    />
                                ))}
                            </FormProvider>
                        </div>
                        <div className={styles.btn}>
                            <Button
                                className={styles.textBtn}
                                onClick={() =>
                                    append({
                                        id_item: uniqid(),
                                        project: '',
                                        manager: '',
                                        branches: '',
                                        territory: '',
                                        project_retails: '',
                                        questionnaires: '',
                                    })
                                }
                                color="secondary"
                            >
                                {t('catalog.team.addProject')}
                            </Button>
                        </div>
                    </div>
                )}
                <ActionsBtns setValue={setValue} isEdit={isEdit} isUser={isUser} />
            </form>
        </div>
    );
};
