import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './PlanogramsDetails.module.scss';
import { Header, Title, BackLink, Select, TextField, Loader } from '@components';
import { Book } from '@/navigation/Book';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { createPlanogramRequest, editPlanogramRequest, getPlanogram } from '@apiFeature/planograms';
import { filesUploadList } from '@apiFeature/fileService';
import { deleteFeedPhoto } from '@apiFeature/feeds';
import { fetchFeedsPhotos } from '@api/visitFeed/visitFeed.api';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import SettingsSelectors from '@redux/settings/selectors';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import { useAppDispatch, useAppSelector } from '@tools/hooks/redux';
import { extractErrorMessage, getOptions, sortFilterLabels } from '@tools/utils/functions';
import { PlanogramTypesName } from '@apiFeature/planograms/types';
import Photos from './Photos/Photos';
import ActionsBtns from './ActionsBtns/ActionsBtns';

export const PlanogramsDetails: FC = () => {
    const session_project_id = sessionStorage.getItem('project_id');
    const { planogramId } = useParams();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('project_id');
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');
    const dispatch = useAppDispatch();
    const { projectsList, loading: projectsLoading } = useAppSelector(
        ProjectsSelectors.projectsSelectors
    );
    const projectsListId = projectsList?.[0]?.id;

    const { settingsAllUser: settings } = useAppSelector(SettingsSelectors.settingsState);
    const planogramsPermissionWebCreate =
        settings?.find((s) => s.code === 'web_planograms_permission')?.setting_value === 'CREATE';

    const [loading, setLoading] = useState<boolean>(false);

    const defaultErrorText = t('messages.fieldRequired');
    const validationSchema = object({
        name: string().required(defaultErrorText),
        project_id: string().required(defaultErrorText),
        type: string().required(defaultErrorText),
    });
    const form = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) });
    const { control, setValue, handleSubmit, reset, formState, watch } = form;
    const { defaultValues, isValid, isDirty, dirtyFields } = formState;
    const photosDelete = watch('photosDelete') || [];

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        if (projectsListId && !planogramId) {
            const defaultValues = {
                name: '',
                project_id: projectId || session_project_id || projectsListId,
                type: '',
                text: '',
            };
            reset(defaultValues);
        }
    }, [projectsListId]);

    useEffect(() => {
        getProjects();
    }, []);

    const getDataPlanogram = () => {
        setLoading(true);
        getPlanogram(planogramId)
            ?.then((result) => {
                const { name, project_id, type, text } = result?.planogram || {};
                const defaultValues = {
                    name,
                    project_id,
                    type,
                    text,
                };
                reset(defaultValues);
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (planogramId) getDataPlanogram();
    }, [planogramId]);

    const onError = () => {
        if (!isValid) {
            enqueueSnackbar(t('messages.invalidForm'), {
                variant: 'error',
            });
        }
    };

    const onSubmit = ({ photosAdd, photosDelete, ...data }) => {
        if (isDirty || Object.keys(dirtyFields).length) {
            setLoading(true);

            const message = planogramId
                ? t('catalog.planograms.updateSuccess')
                : t('catalog.planograms.createSuccess');
            const request = planogramId
                ? editPlanogramRequest(planogramId, data)
                : createPlanogramRequest(data);

            request
                .then(async (res) => {
                    const { planogram } = res || {};
                    const { id } = planogram || {};

                    if (photosDelete?.length > 0) {
                        await fetchFeedsPhotos({ generic_id: id, is_preview: true })
                            .then(async (images) => {
                                if (images?.length > 0) {
                                    for (let photoDeleteId of photosDelete) {
                                        await deleteFeedPhoto(photoDeleteId).catch((e) => {
                                            enqueueSnackbar(extractErrorMessage(e), {
                                                variant: 'error',
                                            });
                                        });
                                        const findPreview = images.find(
                                            (img) =>
                                                img.extend_data?.original_file_link_id ===
                                                photoDeleteId
                                        );
                                        if (findPreview) {
                                            await deleteFeedPhoto(findPreview.id).catch((e) => {
                                                enqueueSnackbar(extractErrorMessage(e), {
                                                    variant: 'error',
                                                });
                                            });
                                        }
                                    }
                                }
                            })
                            .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }));
                    }

                    if (photosAdd?.length > 0) {
                        for (let photoResult of photosAdd) {
                            const { url } = photoResult;
                            const requestForUploadFiles = {
                                file: url,
                                objects: [
                                    {
                                        object_id: id,
                                        type: 'sfa_planogram',
                                    },
                                ],
                            };
                            await filesUploadList(requestForUploadFiles).catch((e) => {
                                enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
                            });
                        }
                    }

                    enqueueSnackbar(message, { variant: 'success' });

                    navigate(Book.catalog.planograms);
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            enqueueSnackbar(t('messages.notChanged'), { variant: 'info' });
        }
    };

    const projectOptions = getOptions(projectsList, 'full_name');
    const planogramTypeOptions = sortFilterLabels(
        Object.keys(PlanogramTypesName).map((type) => {
            return {
                value: type,
                label: PlanogramTypesName[type],
            };
        })
    );

    return (
        <div className={styles.main}>
            {loading && <Loader />}
            <Header type="inner">
                <Title>
                    <BackLink link={Book.catalog.planograms} />
                    {planogramId ? (
                        <>
                            {t('catalog.planograms.title')} {defaultValues?.name}
                        </>
                    ) : (
                        t('catalog.planograms.create')
                    )}
                </Title>
            </Header>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className={styles.formWrapper}>
                    <div className={styles.formItems}>
                        <div className={styles.formItem}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label={t('common.name')}
                                        onChange={onChange}
                                        value={value || ''}
                                        variant="outlined"
                                        error={error}
                                        disabled={!planogramsPermissionWebCreate}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Controller
                                name="project_id"
                                control={control}
                                render={({
                                    field: { name, value, ref },
                                    fieldState: { error },
                                }) => (
                                    <Select
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        label={t('common.project')}
                                        onChange={(e) => {
                                            setValue(name, e?.value, {
                                                shouldDirty: true,
                                                shouldValidate: true,
                                            });
                                        }}
                                        value={projectOptions?.find((e) => e.value === value)}
                                        options={projectOptions}
                                        isLoading={projectsLoading}
                                        isClearable={true}
                                        error={error}
                                        disabled={planogramId || !planogramsPermissionWebCreate}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Controller
                                name="type"
                                control={control}
                                render={({
                                    field: { name, value, ref },
                                    fieldState: { error },
                                }) => (
                                    <Select
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        label={t('catalog.planograms.type')}
                                        onChange={(e) => {
                                            setValue(name, e?.value, {
                                                shouldDirty: true,
                                                shouldValidate: true,
                                            });
                                        }}
                                        value={planogramTypeOptions?.find((e) => e.value === value)}
                                        options={planogramTypeOptions}
                                        isClearable={true}
                                        error={error}
                                        disabled={!planogramsPermissionWebCreate}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Controller
                                name="text"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label={t('common.description')}
                                        onChange={onChange}
                                        value={value || ''}
                                        variant="outlined"
                                        error={error}
                                        disabled={!planogramsPermissionWebCreate}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <Photos
                        id={planogramId}
                        setValue={setValue}
                        photosDelete={photosDelete}
                        planogramsPermissionWebCreate={planogramsPermissionWebCreate}
                    />
                </div>
                <ActionsBtns planogramsPermissionWebCreate={planogramsPermissionWebCreate} />
            </form>
        </div>
    );
};
