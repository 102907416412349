import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './PhotoPreloader.module.scss';
import CameraIcon from '@images/svg/camera-gray.svg';
import { PictureAsPdf } from '@mui/icons-material';

interface IPhotoPreloader {
    className?: string | undefined;
    src?: string | undefined;
    type?: string | undefined;
}

const PhotoPreloader: FC<IPhotoPreloader> = ({ className, src, type, ...props }) => {
    return (
        <div {...props} className={classNames(styles.photoPreloader, className)}>
            {type !== 'pdf' ? (
                <img width={50} height={30} src={CameraIcon} alt="" />
            ) : (
                <a href={src} target="_blank" rel="noreferrer">
                    <PictureAsPdf className={styles.icon} />
                </a>
            )}
        </div>
    );
};

export default PhotoPreloader;
