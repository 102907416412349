import React, { FC, useEffect, useState } from 'react';
import styles from './FileUpload.module.scss';
import { PhotoPreloader } from '@components';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@images/svg/delete.svg';
import { removeDuplicatesForImages } from '@tools/utils/functions';

interface IFileUpload {
    types?: string[];
    multiple: boolean;
    existingImages?: { url: string | File; id: string | null; name?: string }[];
    onImagesChange: (images: { url: string | File; id: string | null }[]) => void;
    onImagesDelete?: (deleteIds: string[]) => void;
    signboardPhoto?: File[];
    disabled?: boolean;
    isEdit?: boolean;
}

const FileUpload: FC<IFileUpload> = ({
    types = ['JPG', 'PNG', 'JPEG', 'GIF', 'WEBP'],
    multiple = false,
    existingImages = [],
    onImagesChange,
    onImagesDelete = () => {},
    signboardPhoto,
    disabled = false,
    isEdit = true,
}) => {
    const { t } = useTranslation('translation');
    const [images, setImages] = useState<any>([]);

    useEffect(() => {
        if (existingImages.length > 0) {
            const uniqueImages = removeDuplicatesForImages(existingImages);
            setImages(uniqueImages);
        }
    }, [existingImages]);

    useEffect(() => {
        const [signPhoto] = signboardPhoto || [];
        if (signPhoto && !images?.length) {
            setImages([{ url: signPhoto, id: null }]);
        }
    }, [signboardPhoto]);

    const handleChange = (files) => {
        const newImages = multiple
            ? [...images, ...Array.from(files).map((e) => ({ url: e, id: null }))]
            : [{ url: files, id: null }];
        setImages(newImages);
        onImagesChange(newImages);
    };

    const handleDelete = (index, e) => {
        e.preventDefault();
        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
        onImagesChange(newImages);

        const deleteIds = images.filter((_, i) => i === index).map((e) => e.id);
        if (deleteIds?.length > 0) onImagesDelete(deleteIds);
    };

    return (
        <div className={styles.imageUpload}>
            <div className={styles.previewWrapper}>
                {images &&
                    images?.length > 0 &&
                    images.map((item, index) => {
                        const { url, name = '' } = item || {};
                        const type = (name || url?.name || '')?.split('.').pop();
                        const src = typeof url === 'string' ? url : URL.createObjectURL(url);
                        return (
                            <div key={index} className={styles.imagePreview}>
                                {type !== 'pdf' ? (
                                    <img
                                        src={src}
                                        alt={`preview ${index}`}
                                        className={styles.image}
                                    />
                                ) : (
                                    <PhotoPreloader
                                        className={styles.image}
                                        src={src}
                                        type={type}
                                    />
                                )}
                                {isEdit && (
                                    <button
                                        className={styles.deleteButton}
                                        onClick={handleDelete.bind(this, index)}
                                    >
                                        <img src={DeleteIcon} alt="delete" />
                                    </button>
                                )}
                            </div>
                        );
                    })}
            </div>
            {isEdit && (
                <FileUploader
                    handleChange={handleChange}
                    name="files"
                    types={types}
                    multiple={multiple}
                    disabled={disabled}
                    label={t('messages.dragOrSelect')}
                />
            )}
        </div>
    );
};

export default FileUpload;
