import React, { useState } from 'react';
import styles from './ImportsJournalHeader.module.scss';
import { Button, Dialog } from '@components';
import { useTranslation } from 'react-i18next';
import { ImportDialog } from '../ImportDialog/ImportDialog';
import { useSnackbar } from 'notistack';
import { importFile } from '@apiFeature/importProcesses';

const ImportsJournalHeader = ({ setImportedFile, form }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');
    const [isModalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { watch, setValue } = form;
    const project = watch('project');
    const projectForImport = watch('projectForImport');
    const kindForImport = watch('kindForImport');
    const [selectedFile, setSelectedFile] = useState(null);
    const handleImport = () => {
        if (!selectedFile) {
            enqueueSnackbar(t('messages.fileNotSelected'), { variant: 'error' });
            return;
        }

        if (projectForImport && kindForImport) {
            setIsLoading(true);
            importFile({
                project_id: projectForImport,
                kind: kindForImport,
                file: selectedFile,
            }).then((res) => {
                setImportedFile(res?.import_file_process?.id)
                enqueueSnackbar(t('importJournal.fileAccepted'), { variant: 'success' });
                setSelectedFile(null);
                setModalOpen(false);

            }).catch((error) => {
                const errorMessage = error?.message || error?.data?.detail || t('messages.unknownError');
                enqueueSnackbar(errorMessage, { variant: 'error' });
            }).finally(() => {
                setIsLoading(false);
            })
        } else {
            enqueueSnackbar(t('messages.paramsRequired'), { variant: 'error' });
        }
    };

    const handleCancel = () => {
        setModalOpen(false);
        setSelectedFile(null);
        setValue('project', projectForImport)
    };

    const handleOpenModal = () => {
        setModalOpen(true);
        setValue('projectForImport', project)
    }

    return (

        <div className={styles.renderHeader}>
            <Button variant="outlined" onClick={() => handleOpenModal()}>
                {t('importJournal.import')}
            </Button>
            {isModalOpen &&
                <Dialog
                    id='importModal'
                    isOpenDialog={isModalOpen}
                    setCloseDialog={handleCancel}
                    title={t('importJournal.importFile')}
                    actions={
                        <>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleImport()}
                                disabled={isLoading}
                            >
                                {t('importJournal.import')}
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCancel}
                                disabled={isLoading}
                            >
                                {t('buttons.cancel').toLocaleUpperCase()}
                            </Button>
                        </>
                    }
                >
                    <ImportDialog
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        projectForImport={projectForImport}
                        kindForImport={kindForImport}
                        setValue={setValue}
                        isLoading={isLoading}
                    />
                </Dialog>
            }
        </div>

    );
};

export default ImportsJournalHeader;