import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRoles } from '@tools/hooks/useRoles';
import { PhotoPreloader, ToggleButtonGroup, Checkbox } from '@components';
import { Tooltip } from '@mui/material';
import { ZoomInMap, ZoomOutMap, GridOn, GridView, CropDin } from '@mui/icons-material';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { FileLink } from '@types/files';
import { getDateGMT } from '@tools/utils/date.util';
import Slider from 'react-slick';
import styles from './Slider.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from 'react-redux';
import SettingsSelectors from '@redux/settings/selectors';
import { convertToOriginalDate } from '@tools/utils/utcOffsetConvert';

const List = ({ children, ...props }) => {
    const { className, type, ratio, grid, isSmall } = props;
    return (
        <div
            className={classNames(
                styles.list,
                className,
                styles[type],
                styles[ratio],
                styles[grid],
                {
                    [styles.isSmall]: isSmall,
                }
            )}
        >
            {children}
        </div>
    );
};

interface ISlider {
    photos: FileLink[] | null;
    className?: string | undefined;
    id?: string;
    description?: {};
    utc_offset?: number;
    type?: 'carousel' | 'list' | 'listText';
    isSmall?: boolean;
    isViewSettings?: boolean;
    countPhotoVisitObj?: { id: string };
    isSelectable?: boolean;
    selectedPhotoIds?: string[];
    setSelectedPhotoIds?: Dispatch<SetStateAction<string[]>>;
}

const SliderSlick: FC<ISlider> = (props) => {
    const {
        className,
        photos,
        id,
        description = {},
        utc_offset,
        type = 'carousel',
        isSmall,
        isViewSettings,
        countPhotoVisitObj,
        isSelectable = false,
        selectedPhotoIds,
        setSelectedPhotoIds,
    } = props;
    const { t } = useTranslation('translation');

    const { clientPermission } = useRoles();

    const length = photos?.length || 0;
    const windowWidth = window?.innerWidth;
    const classes = classNames(styles.slider, className, { [styles.isSmall]: isSmall });
    const { settingsAllUser: userSettings } = useSelector(SettingsSelectors.settingsState);

    const isHideTimeZone =
        userSettings?.find((s) => s.code === 'feeds_visit_timezone')?.setting_value === 'FALSE';
    const isHideTimeZoneCheckers =
        userSettings?.find((s) => s.code === 'visit_check_visit_timezone')?.setting_value ===
        'FALSE';

    let slidesCount = 8;
    if (windowWidth <= 1500) {
        slidesCount = 6;
    }
    if (windowWidth <= 1300) {
        slidesCount = 5;
    }
    if (windowWidth <= 1100) {
        slidesCount = 4;
    }

    const settings = {
        className: classNames(classes, { [styles.notСomplete]: length <= slidesCount }),
        arrows: length > slidesCount,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesCount,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    className: classNames(classes, { [styles.notСomplete]: length <= 6 }),
                    arrows: length > 6,
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    className: classNames(classes, { [styles.notСomplete]: length <= 5 }),
                    arrows: length > 5,
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 1100,
                settings: {
                    className: classNames(classes, { [styles.notСomplete]: length <= 4 }),
                    arrows: length > 4,
                    slidesToShow: 4,
                },
            },
        ],
    };

    const [emptyPhotos, setEmptyPhotos] = useState<number>(0);

    const handlePhotoSelect = (photoId, e) => {
        const isChecked = e.target.checked;
        if (setSelectedPhotoIds) {
            setSelectedPhotoIds((prevSelected) => {
                const newSelectedPhotos = isChecked
                    ? [...prevSelected, photoId]
                    : prevSelected.filter((id) => id !== photoId);
                return newSelectedPhotos;
            });
        }
    };

    useEffect(() => {
        if (id && countPhotoVisitObj && countPhotoVisitObj.hasOwnProperty(id)) {
            setEmptyPhotos(+countPhotoVisitObj[id] - length);
        }
    }, [countPhotoVisitObj, length, id]);

    const Component = type === 'carousel' ? Slider : List;

    const [viewSettings, setViewSettings] = useState<{ ratio: string; grid: string }>({
        ratio: 'ratio-1',
        grid: 'grid-1',
    });
    const { ratio, grid } = viewSettings || {};
    const handleChange = (name, value: string) => {
        if (value !== null) setViewSettings({ ...viewSettings, [name]: value });
    };

    const ratioElements = [
        {
            value: 'ratio-1',
            label: (
                <Tooltip title={t('photo.originalRatio')} placement="bottom-end" arrow>
                    <ZoomInMap />
                </Tooltip>
            ),
        },
        {
            value: 'ratio-2',
            label: (
                <Tooltip title={t('photo.squareFormat')} placement="bottom-end" arrow>
                    <ZoomOutMap />
                </Tooltip>
            ),
        },
    ];
    const gridElements = [
        {
            value: 'grid-1',
            label: (
                <Tooltip title={t('photo.smallGrid')} placement="bottom-end" arrow>
                    <GridOn />
                </Tooltip>
            ),
        },
        {
            value: 'grid-2',
            label: (
                <Tooltip title={t('photo.middleGrid')} placement="bottom-end" arrow>
                    <GridView />
                </Tooltip>
            ),
        },
        {
            value: 'grid-3',
            label: (
                <Tooltip title={t('photo.bigGrid')} placement="bottom-end" arrow>
                    <CropDin />
                </Tooltip>
            ),
        },
    ];

    return (
        <>
            {isViewSettings &&
                type === 'list' &&
                ((photos && photos?.length > 0) || (emptyPhotos && emptyPhotos > 0)) && (
                    <div className={styles.viewSettings}>
                        <ToggleButtonGroup
                            color="secondary"
                            value={ratio}
                            exclusive
                            onChange={(_, value) => handleChange('ratio', value)}
                            elements={ratioElements}
                        />
                        <ToggleButtonGroup
                            color="secondary"
                            value={grid}
                            exclusive
                            onChange={(_, value) => handleChange('grid', value)}
                            elements={gridElements}
                        />
                    </div>
                )}
            <Component {...props} {...settings}>
                {photos &&
                    photos?.length > 0 &&
                    photos.map((el) => {
                        const {
                            id: photoId,
                            file,
                            utc_offset: photo_utc_offset,
                            file_created_at_original,
                            number,
                        } = el || {};
                        const fileType = (file?.name || '')?.split('.').pop();

                        let items = { ...description };
                        if (file_created_at_original) {
                            items = {
                                ...items,
                                [t('feed.photoDate')]: {
                                    value:
                                        isHideTimeZone || isHideTimeZoneCheckers
                                            ? convertToOriginalDate(
                                                  file_created_at_original,
                                                  utc_offset
                                              )
                                            : getDateGMT(
                                                  file_created_at_original,
                                                  photo_utc_offset || utc_offset,
                                                  'D MMMM YYYY, HH:mm:ss'
                                              ),
                                    isVisible: !clientPermission,
                                },
                            };
                        }
                        return (
                            <div
                                key={photoId}
                                className={classNames({
                                    [styles.item]: !file?.text,
                                    [styles.item_text]: file?.text,
                                })}
                            >
                                {isSelectable && (
                                    <div className={styles.selectPhoto}>
                                        <Checkbox
                                            value={selectedPhotoIds?.includes(photoId)}
                                            onChange={(e) => handlePhotoSelect(photoId, e)}
                                        />
                                    </div>
                                )}
                                {!file?.text && <PhotoPreloader src={file?.url} type={fileType} />}
                                {file?.url && (
                                    <a
                                        data-fancybox={`gallery-${id}`}
                                        data-fancybox-id={photoId}
                                        href={file?.url_original || file?.url}
                                        data-download-src={file?.url_original || file?.url}
                                    >
                                        {!file?.text ? (
                                            <>
                                                <img
                                                    src={file?.url}
                                                    alt=""
                                                    className={styles.img}
                                                />
                                                {number && (
                                                    <span className={styles.number}>{number}</span>
                                                )}
                                            </>
                                        ) : (
                                            file?.text
                                        )}
                                        <div hidden className="caption">
                                            <ListMapper
                                                items={items}
                                                className={styles.description}
                                            />
                                        </div>
                                    </a>
                                )}
                            </div>
                        );
                    })}
                {emptyPhotos && emptyPhotos > 0
                    ? Array.from(Array(emptyPhotos)).map((_, i) => (
                          <div key={i} className={styles.item}>
                              <PhotoPreloader />
                          </div>
                      ))
                    : null}
            </Component>
        </>
    );
};

export default SliderSlick;
