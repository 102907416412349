import React, { useCallback, useEffect } from 'react';
import styles from './PlanogramsFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select } from '@components';
import { getOptions, sortFilterLabels } from '@tools/utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import { PlanogramTypesName } from '@apiFeature/planograms/types';

const PlanogramsFilters = ({ defaultValues, form }) => {
    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const projectsListId = projectsList?.[0]?.id;

    const { t } = useTranslation('translation');

    const { reset, watch, setValue } = form;
    const currValues = watch();
    const { project_id: pr_id, planogram_type } = currValues;
    const project_id = pr_id || projectsListId;

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        if (projectsList.length > 0 && !project_id) setValue('project_id', projectsListId);
    }, [projectsList]);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        reset({
            ...defaultValues,
            project_id: projectsListId,
        });
    };

    const projectOptions = getOptions(projectsList, 'full_name');
    const planogramTypeOptions = sortFilterLabels(
        Object.keys(PlanogramTypesName).map((type) => {
            return {
                value: type,
                label: PlanogramTypesName[type],
            };
        })
    );

    const filters = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            onChange,
            value: project_id
                ? projectOptions?.find((e) => e.value === project_id)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: Select,
            name: 'planogram_type',
            label: t('catalog.planograms.type'),
            onChange,
            value: planogramTypeOptions?.find((e) => e.value === planogram_type),
            options: planogramTypeOptions,
            isClearable: true,
        },
    ];

    return { handleReset, filters };
};

export default PlanogramsFilters;
