import { TQuestionResult } from '@apiFeature/questionnaires/type';

export const getDefaultValuesFromFeedItem = (feed, projectId) => {
    const {
        is_ended_in_place,
        comment,
        start_time,
        end_time,
        user,
        project_outlets,
        visit_fail_result,
    } = feed || {};
    const { id: user_id } = user || {};
    const [project_outlet] = project_outlets || [];
    const { id: project_outlet_id, longitude, latitude, project_id } = project_outlet || {};
    const { id: visit_fail_result_id } = visit_fail_result || {};

    const defaultValues = {
        project_id: project_id || projectId,
        project_outlet_id,
        user_id,
        date: start_time,
        startTime: start_time,
        endTime: end_time,
        problem: visit_fail_result_id,
        is_ended_in_place: is_ended_in_place === undefined ? true : is_ended_in_place,
        comment,
        start_point_lat: latitude,
        start_point_lon: longitude,
        end_point_lat: latitude,
        end_point_lon: longitude,
    };

    return { defaultValues, project_outlet_id };
};

export const setAnswersFromFilledQuestionnaires = (questionnaires, setValue) => {
    if (questionnaires && questionnaires?.length > 0) {
        questionnaires.forEach((q) => {
            const { questionnaire_steps } = q || {};
            if (questionnaire_steps && questionnaire_steps?.length > 0) {
                questionnaire_steps.forEach((step) => {
                    const { questions } = step || {};
                    if (questions && questions?.length > 0) {
                        questions.forEach((question) => {
                            const { id: questionId, question_result, kind } = question || {};
                            const {
                                answer,
                                file_links,
                                id: question_result_id,
                            } = question_result || {};
                            const registerStr = `questionnaires[${q.id}].questionnaire_steps[${step.id}][${questionId}]`;
                            if (kind === 'one_answer') {
                                const [resultAnswer] = answer || [];
                                setValue(registerStr, resultAnswer || null);
                            } else {
                                setValue(registerStr, answer || null);
                            }
                            if (file_links && file_links?.length > 0) {
                                const registerStr = `questions_file_links_ids[${questionId}]`;
                                setValue(registerStr, question_result_id);
                            }
                        });
                    }
                });
            }
        });
    }
};

export const getAnswersAndPhotosFromState = (currValues, questionnairesFromState) => {
    let question_results: Array<TQuestionResult> = [];
    const { questionnaires, photosQuestion } = currValues || {};
    if (questionnaires) {
        Object.keys(questionnaires).forEach((qId) => {
            const currentQuestionnaire = questionnaires[qId];
            const qFromState = questionnairesFromState?.find((q) => q.id === qId);
            const { questionnaire_steps: qStepsFromState } = qFromState || {};
            const { added, questionnaire_steps } = currentQuestionnaire || {};
            if (added && questionnaire_steps && qStepsFromState) {
                Object.keys(questionnaire_steps).forEach((stepId) => {
                    const currentStep = questionnaire_steps[stepId];
                    const qStepItemFromState = qStepsFromState.find((s) => s.id === stepId);
                    const { questions: questionsFromState } = qStepItemFromState || {};
                    Object.keys(currentStep).forEach((questionId) => {
                        const answer = currentStep[questionId];
                        const questionItemFromState = questionsFromState.find(
                            (q) => q.id === questionId
                        );
                        const { kind, question_result } = questionItemFromState || {};
                        const { id: question_result_id } = question_result || {};
                        if (kind === 'one_answer') {
                            question_results.push({
                                question_id: questionId,
                                question_result_id,
                                answer: answer ? [answer] : null,
                            });
                        }
                        if (
                            [
                                'number_answer',
                                'integer_answer',
                                'many_answers',
                                'your_answer',
                                'photo_answer',
                            ].includes(kind)
                        ) {
                            question_results.push({
                                question_id: questionId,
                                question_result_id,
                                answer: answer || null,
                            });
                        }
                    });
                });
            }
        });
    }
    let photo_results: Array<TQuestionResult> = [];
    if (photosQuestion) {
        Object.keys(photosQuestion).forEach((questionId) => {
            const { files, question_result_id } = photosQuestion[questionId] || {};
            if (files && files.length > 0) {
                files.forEach((photo) => {
                    if (typeof photo !== 'string') {
                        photo_results.push({
                            question_id: questionId,
                            question_result_id,
                            answer: photo,
                        });
                    }
                });
            }
        });
    }
    return { question_results, photo_results };
};
