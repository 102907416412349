import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { Button } from '@components';
import styles from './ActionsBtns.module.scss';
import { Book } from '@/navigation/Book';

const ActionsBtns: FC = ({ planogramsPermissionWebCreate }) => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const navigationType = useNavigationType();
    const handleCancel = () => {
        navigate(navigationType === 'POP' ? Book.catalog.planograms : -1);
    };

    return (
        <div className={styles.btns}>
            {planogramsPermissionWebCreate && (
                <Button variant="contained" color="secondary" type="submit">
                    {t('buttons.save')}
                </Button>
            )}
            <Button variant="outlined" color="secondary" onClick={handleCancel}>
                {t('buttons.cancel')}
            </Button>
        </div>
    );
};

export default ActionsBtns;
