import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './VisitScheduleData.module.scss';
import { TableBlueprint, Loader, Pagination } from '@components';
import {
    fetchVisitingScheduleListWeb,
    fetchVisitingScheduleOutlet,
    fetchVisitingScheduleStatuses,
} from '@apiFeature/visitingSchedule';
import { fetchUserList } from '@apiFeature/user';
import { fetchProgramPramsList, fetchProgram } from '@apiFeature/addressProgram';
import { useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { t } from 'i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { PopoverProps } from '@mui/material/Popover';
import { FilterValue } from '@apiFeature/types';
import VisitScheduleCell from './VisitScheduleCell/VisitScheduleCell';
import { getColumnsHiddenActive, getScheduleDates, getProgramTime } from '../helpers/helpers';
import VisitScheduleGroupEditing from './VisitScheduleGroupEditing/VisitScheduleGroupEditing';

const defaultItemsPerPage = {
    label: '10',
    value: 10,
};

const columnsDefault = [
    {
        field: 'external_code',
        headerName: t('common.outletCode'),
        minWidth: 150,
    },
    {
        field: 'address',
        headerName: t('visits.address'),
        minWidth: 500,
    },
    { field: 'branch_name', headerName: t('feed.branch'), minWidth: 200 },
    { field: 'city_name', headerName: t('common.city'), minWidth: 200 },
    {
        field: 'project_retail_name',
        headerName: t('common.retail'),
        minWidth: 200,
    },
    { field: 'user_name', headerName: t('common.user'), minWidth: 200 },
    {
        field: 'user_id',
        headerName: t('reports.reports.userId'),
        minWidth: 280,
    },
    {
        field: 'user_phone',
        headerName: t('reports.reports.userPhone'),
        minWidth: 160,
    },
    {
        field: 'manager_name',
        headerName: t('common.manager'),
        minWidth: 200,
    },
    {
        field: 'manager_phone',
        headerName: t('reports.reports.managerPhone'),
        minWidth: 160,
    },
    {
        field: 'time_plan',
        headerName: t('reports.reports.plannedTime'),
        minWidth: 220,
    },
    {
        field: 'visit_plan',
        headerName: t('reports.reports.plannedCount'),
        minWidth: 220,
    },
];

let columns: any[] = [];

const VisitScheduleData = ({ defaultValues, filters, form, settings }) => {
    const { setValue, watch } = form;
    const {
        project_id: dpID,
        date: { start: defStart, end: defEnd } = {},
        type: defType,
    } = defaultValues || {};
    const {
        project_id: pID,
        date: { start: curStart, end: curEnd } = {},
        type,
        update_id,
        ...filtersData
    } = filters || {};
    const projectId = pID || dpID;
    const start = curStart || defStart;
    const end = curEnd || defEnd;

    const pageNumber = 1;
    const [itemsPerPage, setItemsPerPage] = useState<FilterValue>(defaultItemsPerPage);
    const limit = Number(itemsPerPage.value);

    const offsetFromPageNumber = (pageNumber - 1) * limit;
    const paginationModelDefault = {
        offset: offsetFromPageNumber > 0 ? offsetFromPageNumber : 0,
        limit,
    };

    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const [isLoading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [data, setData] = useState({ rows: [], total: 0, offset: 0 });
    const { rows, total = 0, offset = 0 } = data || {};

    const { enqueueSnackbar } = useSnackbar();

    const handleErr = (e) => {
        const errorMessage = e?.message || e?.data?.detail || t('messages.unknownError');
        enqueueSnackbar(errorMessage, { variant: 'error' });
    };

    const getVisitingSchedule = useCallback(
        (offset = 0, limit) => {
            setLoading(true);

            const [project] = projectsList || [];
            const { id } = project || {};
            const project_id = projectsList.find((p) => p.id === projectId)?.id ?? id;
            const project_ids = [project_id];

            const date_from = moment(start).format('YYYY-MM-DD');
            const date_to = moment(end).format('YYYY-MM-DD');

            fetchVisitingScheduleListWeb({
                project_ids,
                ...filtersData,
                date_from,
                date_to,
                type: type || defType,
                pagination: {
                    limit,
                    offset,
                },
            })
                .then(async (props) => {
                    const { page } = props || {};
                    const { items = [], total = 0, offset = 0 } = page || {};
                    let visiting_schedule_table: any = [...items];

                    const {
                        route_points_ids: rPIds,
                        project_outlet_ids: pOIds,
                        user_ids: uIds,
                        manager_ids: mIds,
                    } = items?.reduce(
                        (acc, item) => ({
                            project_outlet_ids: [...acc.project_outlet_ids, item.project_outlet_id],
                            user_ids: [...acc.user_ids, item.user_id],
                            manager_ids: [...acc.manager_ids, ...(item.manager_ids || [])],
                            route_points_ids: [
                                ...acc.route_points_ids,
                                ...(item.route_point_ids || []),
                            ],
                        }),
                        {
                            project_outlet_ids: [],
                            user_ids: [],
                            manager_ids: [],
                            route_points_ids: [],
                        }
                    ) || {};

                    const route_points_ids = [...new Set(rPIds)].filter((e) => e);
                    const project_outlet_ids = [...new Set(pOIds)].filter((e) => e);
                    const user_ids = [...new Set(uIds)].filter((e) => e);
                    const manager_ids = [...new Set(mIds)].filter((e) => e);

                    let outlets_info: any = [];
                    if (project_outlet_ids.length > 0) {
                        outlets_info = await fetchVisitingScheduleOutlet(project_outlet_ids)
                            .then(({ ptts }) => ptts)
                            .catch((error) => handleErr(error));
                    }

                    let users: any = [];
                    if (user_ids.length > 0) {
                        users = await fetchUserList({ ids: user_ids })
                            .then(({ items }) => items)
                            .catch((error) => handleErr(error));
                    }

                    let managers: any = [];
                    if (manager_ids.length > 0) {
                        managers = await fetchUserList({ ids: manager_ids })
                            .then(({ items }) => items)
                            .catch((error) => handleErr(error));
                    }

                    let visits: any = [];
                    if (route_points_ids.length > 0) {
                        visits = await fetchVisitingScheduleStatuses({
                            route_points_ids,
                            date_from,
                            date_to,
                        })
                            .then(({ visits }) => visits)
                            .catch((error) => handleErr(error));
                    }

                    let program_params_items = [];
                    const visiting_schedule_column_time_visit_plan =
                        settings?.find(
                            (s) =>
                                s.code === 'visiting_schedule_column_time_plan' ||
                                s.code === 'visiting_schedule_column_visit_plan'
                        )?.setting_value === 'TRUE';
                    if (visiting_schedule_column_time_visit_plan) {
                        const period_start_time_lower = moment(start)
                            .set({
                                hours: 0,
                                minutes: 0,
                                seconds: 0,
                            })
                            .format('YYYY-MM-DDTHH:mm:ss');
                        const period_end_time_upper = moment(end)
                            .set({
                                hours: 23,
                                minutes: 59,
                                seconds: 59,
                            })
                            .format('YYYY-MM-DDTHH:mm:ss');

                        const res = await fetchProgramPramsList({
                            project_outlet_ids,
                            period_start_time_lower: period_end_time_upper,
                            period_end_time_upper: period_start_time_lower,
                        });
                        const { program_params } = res || {};
                        let { items } = program_params || {};

                        if (items?.length > 0) {
                            const programIds = items.map((e) => e.program_id);

                            const program_id_ids = [...new Set(programIds)].filter((e) => e);

                            if (program_id_ids?.length > 0) {
                                const programs = await Promise.all(
                                    program_id_ids?.map(async (e) => {
                                        const { program } = (await fetchProgram(e)) || {};
                                        return program;
                                    })
                                );

                                items = items.map((e) => ({
                                    ...e,
                                    program_time: getProgramTime(e, programs),
                                }));
                            }

                            program_params_items = items;
                        }
                    }

                    visiting_schedule_table = visiting_schedule_table.map((e, i) => {
                        const {
                            project_outlet_external_code: external_code,
                            branch_name,
                            city_name,
                            project_retail_name,
                            project_outlet_address,
                        } = outlets_info.find(
                            (item) => item.project_outlet_id === e.project_outlet_id
                        ) || {};

                        const {
                            timing,
                            visits_per_month,
                            program_time = '',
                        } = program_params_items.find(
                            (item) => item.project_outlet_id === e.project_outlet_id
                        ) || {};

                        const {
                            id: user_id,
                            last_name: user_last_name = '',
                            first_name: user_first_name = '',
                            phone: user_phone,
                        } = users.find((item) => item.id === e.user_id) || {};

                        const managers_data =
                            managers.filter((item) => e.manager_ids?.includes(item.id)) || {};
                        const manager_name = managers_data
                            .map((e) => `${e.last_name || ''} ${e.first_name || ''}`)
                            ?.join(', ');
                        const manager_phone = managers_data.map((e) => e.phone)?.join(', ');

                        const { statuses } =
                            visits.find(
                                (item) =>
                                    item.project_outlet_id === e.project_outlet_id &&
                                    item.user_id === e.user_id
                            ) || {};

                        return {
                            ...e,
                            id: i,
                            external_code,
                            address: project_outlet_address,
                            branch_name,
                            city_name,
                            project_retail_name,
                            user_name: `${user_last_name} ${user_first_name}`,
                            user_phone,
                            manager_name,
                            manager_phone,
                            user_id,
                            statuses,
                            project_id,
                            time_plan: program_time || timing ? program_time + ' - ' + timing : '',
                            visit_plan:
                                program_time || visits_per_month
                                    ? program_time + ' - ' + visits_per_month
                                    : '',
                        };
                    });

                    setData({ rows: visiting_schedule_table, total, offset });
                })
                .catch((error) => {
                    handleErr(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [projectsList, filters]
    );

    useEffect(() => {
        const { offset, limit } = paginationModel;
        if (projectsList?.length > 0) getVisitingSchedule(offset, limit);
    }, [paginationModel, projectsList?.length]);

    useEffect(() => {
        if (update_id) {
            const subscription = watch((values, { name }) => {
                if (name !== 'update_id' && values?.update_id === update_id)
                    setValue('update_id', null);
            });
            return () => subscription.unsubscribe();
        }
    }, [watch, update_id]);

    useEffect(() => {
        if (Object.keys(filters)?.length > 0) {
            setPaginationModel(
                update_id ? { offset: paginationModel?.offset, limit } : paginationModelDefault
            );
        }

        if (start && end) {
            const scheduleDates = getScheduleDates(start, end);
            const columnsDates = scheduleDates.map(({ date, monthDay, weekDay }, i) => ({
                date,
                field: 'statuses' + i,
                headerName: (
                    <div className={styles.date}>
                        <div className={styles.monthDay}>{monthDay}</div> {weekDay}
                    </div>
                ),
                renderCell: ({ row }) => (
                    <VisitScheduleCell
                        status={
                            row?.statuses && row?.statuses.length > 0
                                ? row?.statuses.find((st) => st.date === date)
                                : ''
                        }
                        row={row}
                        date={date}
                        setValue={setValue}
                        settings={settings}
                    />
                ),
                minWidth: 40,
            }));

            const columnsHiddenActive = getColumnsHiddenActive(settings) || [];
            const columnsDefaultData = columnsDefault.filter(
                (e) => !columnsHiddenActive.includes(e.field)
            );

            columns = [...columnsDefaultData, ...columnsDates];
        }
    }, [filters, settings]);

    const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl']>(null);
    const clickContextMenu = (data) => {
        setAnchorEl(data);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {isLoading && <Loader />}
            <TableBlueprint
                rows={rows}
                columns={columns}
                rowCount={total}
                numFrozenColumns={1}
                isLoading={isLoading}
                renderBodyContextMenu={(props) => (
                    <VisitScheduleGroupEditing
                        {...props}
                        anchorEl={anchorEl}
                        setValue={setValue}
                        project_id={projectId}
                        handleClose={handleClose}
                        settings={settings}
                    />
                )}
                clickContextMenu={clickContextMenu}
                className={classNames(styles.tableBlueprint, {
                    [styles.tableBlueprintHeight]: rows?.length >= 20,
                })}
                hasSun
            />
            <Pagination
                total={total}
                limit={limit}
                offset={offset}
                pageNumber={pageNumber}
                hasNumbersPagination={true}
                isSearchParams={false}
                onPaginationModelChange={setPaginationModel}
                setItemsPerPage={setItemsPerPage}
                className={styles.pagination}
            />
            {rows && rows.length > 0 && (
                <div className={styles.statuses}>
                    <div className={styles.status}>
                        <div className={styles.actualVisit} /> — {t('visits.completed')}
                    </div>
                    <div className={styles.status}>
                        <div className={styles.inProgressVisit} /> — {t('visits.inProgress')}
                    </div>
                    <div className={styles.status}>
                        <div className={styles.expiredVisit} /> — {t('visits.notCompleted')}
                    </div>
                    <div className={styles.status}>
                        <div className={styles.newVisit} /> — {t('visits.planned')}
                    </div>
                </div>
            )}
        </>
    );
};

export default VisitScheduleData;
