import { clientPassword } from '../ClientPassword';

export const deletePlanogram = (id: string) => {
    return clientPassword.delete(`/planograms/${id}/`);
};

export const createPlanogramRequest = (request) => {
    return clientPassword.post('/planograms/', request);
};

export const fetchPlanogramsList = (request) => {
    return clientPassword.post('/planograms/list/', request);
};

export const editPlanogramRequest = (planogram_id, request) => {
    return clientPassword.patch(`/planograms/${planogram_id}/`, request);
};

export const getPlanogram = (planogram_id, request) => {
    return clientPassword.get(`/planograms/${planogram_id}/planogram/`, request);
};
