import BaseClient from './BaseClient';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { setRefreshTokenRef, getRefreshTokenRef } from './globalRefresh';
import {
    ACCESS_EXPIRES,
    ACCESS_TOKEN,
    CROWD_SERVICE_URL,
    AP_SERVICE_URL,
    IR_SERVICE_URL,
    AUTH_URL,
    BASE_URL,
    FILE_SERVICE_URL,
    IMPORT_SERVICE_URL,
    EXPORT_SERVICE_URL,
    REFRESH_EXPIRES,
    REFRESH_TOKEN,
    REACT_APP_CHECKER,
} from '../api/api.constant';
import { reduxStore } from '../redux/store';
import { authRefreshToken } from './login';
import AuthActions from '@redux/auth/actions';

class ClientPassword extends BaseClient {
    constructor(baseURL: string) {
        super(baseURL);
        this._initializeInterceptors();
    }
    private _initializeInterceptors = () => {
        this.instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
            const token = reduxStore.getState().auth?.accessToken?.token;
            if (token && config.headers) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        this.instance.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            (error) => {
                const originalRequest = error.config;
                const user = reduxStore.getState().auth?.user;
                if (
                    error.response?.status === 401 &&
                    !originalRequest._retry &&
                    originalRequest.url !== '/auth/refresh/'
                ) {
                    originalRequest._retry = true;
                    let refreshTokenRef = getRefreshTokenRef();
                    const refresh_token = reduxStore.getState().auth?.refreshToken?.token;
                    if (refreshTokenRef === null && refresh_token) {
                        refreshTokenRef = authRefreshToken(refresh_token ?? '');
                        setRefreshTokenRef(refreshTokenRef);
                    }
                    return refreshTokenRef
                        ?.then((res) => {
                            reduxStore.dispatch(
                                AuthActions.saveAuth({
                                    tokens: res.tokens,
                                    user,
                                })
                            );
                            localStorage.setItem(ACCESS_TOKEN, res.tokens.access.token);
                            localStorage.setItem(ACCESS_EXPIRES, res.tokens.access.expires_at);
                            localStorage.setItem(REFRESH_TOKEN, res.tokens.refresh.token);
                            localStorage.setItem(REFRESH_EXPIRES, res.tokens.refresh.expires_at);
                            originalRequest.headers.Authorization = `Bearer ${res.tokens?.access?.token}`;
                            return this.instance(originalRequest);
                        })
                        .catch((err) => {
                            // auth/refresh/ - вернул что токен того...
                            if (err?.status === 401) {
                                reduxStore.dispatch(AuthActions.logout());
                            }
                            return Promise.reject(err);
                        })
                        .finally(() => {
                            setRefreshTokenRef(null);
                        });
                }
                return Promise.reject(this.errorAdapter(error));
            }
        );
    };
}

const configUrl = BASE_URL;
const configAuthUrl = AUTH_URL;
const configFileServiceUrl = FILE_SERVICE_URL;
const configImportServiceUrl = IMPORT_SERVICE_URL;
const configExportServiceUrl = EXPORT_SERVICE_URL;
const configCrowdUrl = CROWD_SERVICE_URL;
const configAPUrl = AP_SERVICE_URL;
const configIRUrl = IR_SERVICE_URL;
const configChecker = REACT_APP_CHECKER;

export const clientPassword = new ClientPassword(configUrl as string);
export const clientAuthPassword = new ClientPassword(configAuthUrl as string);
export const clientChecker = new ClientPassword(configChecker as string);
export const clientFileServicePassword = new ClientPassword(configFileServiceUrl as string);
export const clientImportServicePassword = new ClientPassword(configImportServiceUrl as string);
export const crowdServicePassword = new ClientPassword(configCrowdUrl as string);
export const apServicePassword = new ClientPassword(configAPUrl as string);
export const irServicePassword = new ClientPassword(configIRUrl as string);
export const clientExportServicePassword = new ClientPassword(configExportServiceUrl as string);
