import React, { FC, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Loader, FileUpload } from '@components';
import { useSnackbar } from 'notistack';
import { fetchFeedsPhotos } from '@api/visitFeed/visitFeed.api';
import styles from './Photos.module.scss';
import { FileLink } from '@apiFeature/crowd/types';
import { removeDuplicatesForImages } from '@tools/utils/functions';

interface IPhotos {
    id?: string;
    setValue: UseFormReturn['setValue'];
    photosDelete?: string[];
    planogramsPermissionWebCreate?: boolean;
}

const Photos: FC<IPhotos> = ({ id, setValue, photosDelete, planogramsPermissionWebCreate }) => {
    const [isLoading, setLoading] = useState(false);
    const [photos, setPhotos] = useState<FileLink[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!id) return;
        getPhotos();
    }, [id]);

    const getPhotos = async () => {
        setLoading(true);

        await fetchFeedsPhotos({ generic_id: id, is_preview: null })
            .then((images) => {
                if (images?.length > 0) {
                    const arrayOfFiles = images
                        .map((fl) => {
                            const { file, id } = fl || {};
                            const { name, url } = file || {};
                            if (name !== 'preview.webp') {
                                return { url, id, name };
                            }
                        })
                        .filter((i) => i !== undefined);

                    const uniqueFiles = removeDuplicatesForImages(arrayOfFiles);
                    setPhotos(uniqueFiles);
                }
            })
            .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }));

        setLoading(false);
    };

    return (
        <div className={styles.photos}>
            {isLoading && <Loader />}
            <FileUpload
                types={['JPG', 'JPEG', 'PDF']}
                existingImages={photos}
                onImagesChange={(files) => {
                    setValue(
                        'photosAdd',
                        files.filter((e) => e.id === null),
                        {
                            shouldDirty: true,
                            shouldValidate: true,
                        }
                    );
                }}
                onImagesDelete={(deleteIds) => {
                    setValue('photosDelete', [...photosDelete, ...deleteIds], {
                        shouldDirty: true,
                        shouldValidate: true,
                    });
                }}
                multiple={true}
                isEdit={planogramsPermissionWebCreate}
            />
        </div>
    );
};

export default Photos;
