import React, { useEffect, useState } from 'react';
import styles from './Reports.module.scss';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import ReportsBaseFilters from './ReportsBaseFilters/ReportsBaseFilters';
import ReportsHeader from './ReportsHeader/ReportsHeader';
import ReportsData from './ReportsData/ReportsData';
import ReportsFilters from './ReportsFilters/ReportsFilters';
import { useRoles } from '@tools/hooks/useRoles';
import { useURLFilters } from '@tools/hooks/useURLFilters';

export const Reports = () => {
    const { clientPermission } = useRoles();

    const { t } = useTranslation('translation');
    const { setURLFiltersFromDefaultValues } = useURLFilters();

    const project_id = sessionStorage.getItem('project_id');

    const [defaultValues, setDefaultValues] = useState({
        inactive: false,
    });
    useEffect(() => {
        let defaultValuesInit = {
            project_id: project_id !== 'undefined' ? project_id : '',
            kind: 'VISITS',
        };
        if (clientPermission) defaultValuesInit = { ...defaultValuesInit, visit_type: 'visit' };

        const resultFilterValues = setURLFiltersFromDefaultValues(defaultValuesInit);

        setDefaultValues(resultFilterValues);
    }, []);

    const getFilters = (values) => {
        const { project_id, kind, status, export_process_id } = values || {};
        let res = {};
        if (project_id) res = { ...res, project_id };
        if (kind) res = { ...res, kind };
        if (status) res = { ...res, status };
        if (export_process_id) res = { ...res, export_process_id };
        return res;
    };

    return Object.keys(defaultValues)?.length > 0 ? (
        <Layout
            className={styles.layoutReports}
            title={t('reports.reports.title')}
            renderHeader={(props) => <ReportsHeader {...props} defaultValues={defaultValues} />}
            renderBaseFilters={(props) => (
                <ReportsBaseFilters {...props} clientPermission={clientPermission} />
            )}
            renderData={(props) => <ReportsData {...props} clientPermission={clientPermission} />}
            renderFilters={ReportsFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
            type={2}
        />
    ) : (
        <></>
    );
};
