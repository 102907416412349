import { t } from 'i18next';

export const getRoles = (roles) => {
    const isAdmin = roles?.some((val) => val === 'administrator');
    const isChecker = roles?.some((val) => val === 'check_manager' || val === 'head_check_manager');
    const isClientOnly = roles?.every((val) => val === 'client');
    const isClient = roles?.some((val) => val === 'client');
    const isVisitReport = roles?.some((val) => ['report_viewer', 'visits_report'].includes(val));
    const isQuestionnaireReport = roles?.some((val) => val === 'questionnaires_report');
    const isPhotoReport = roles?.some((val) => val === 'photo_report');
    const isCrowd = roles?.some((val) => val === 'crowd_merchandiser');
    const isAnalyst = roles?.some((val) => val === 'analyst');
    const isDepartmentHead = roles?.some((val) => val === 'department_head');
    const isLeader = roles?.some((val) => val === 'leader');
    const isDirectorMerch = roles?.some((val) => val === 'director_merchandiser');
    const isSupervisor = roles?.some((val) => val === 'supervisor');
    const isCheckManager = roles?.some((val) => val === 'check_manager');
    const isHeadCheckManager = roles?.some((val) => val === 'head_check_manager');
    const isTestUser = roles?.some((val) => val === 'test_user');
    const clientPermission =
        isClient && (isClient || isVisitReport || isQuestionnaireReport || isPhotoReport);
    const isAvailableFakeVisit = roles ? roles?.includes('crisis_manager') : false;
    const isClientCrowd = isClient && isCrowd;
    const isManagerVisible = isAnalyst || isDepartmentHead || isLeader || isDirectorMerch;
    const isAvailableDelete = roles
        ? roles?.includes('administrator') ||
          roles?.includes('crisis_manager') ||
          roles?.includes('department_head') ||
          roles?.includes('leader')
        : false;

    const isUserCatalogEnable = roles
        ? roles?.includes('director_merchandiser') || roles?.includes('analyst')
        : false;
    const isCrowdReport = roles?.some((val) =>
        [
            'report_viewer',
            'director_merchandiser',
            'analyst',
            'administrator',
            'department_head',
            'leader',
            'supervisor',
            'questionnaires_report',
        ].includes(val)
    );
    const isTaskReportUpdateAllowed = roles?.some((val) =>
        [
            'crisis_manager',
            'director_merchandiser',
            'analyst',
            'administrator',
            'department_head',
            'leader',
        ].includes(val)
    );
    const isRestoredSignVisible = roles?.some((val) =>
        ['crisis_manager', 'director_merchandiser', 'analyst', 'administrator'].includes(val)
    );
    const isVisitReportAndCrowd = isClient && isVisitReport && isCrowd;

    const res = {
        clientPermission,
        isAdmin,
        isChecker,
        isAvailableFakeVisit,
        isClientOnly,
        isClient,
        isClientCrowd,
        isManagerVisible,
        isAvailableDelete,
        isSupervisor,
        isCrowd,
        isUserCatalogEnable,
        isCheckManager,
        isHeadCheckManager,
        isCrowdReport,
        isVisitReport,
        isQuestionnaireReport,
        isPhotoReport,
        isTestUser,
        isTaskReportUpdateAllowed,
        isAnalyst,
        isRestoredSignVisible,
        isVisitReportAndCrowd,
    };
    return res;
};

export const debounce = <T extends (...args: unknown[]) => ReturnType<T>>(
    callback: T,
    timeout = 1000
): ((...args: Parameters<T>) => void) => {
    let timer: ReturnType<typeof setTimeout>;

    return (...args: Parameters<T>) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            callback(...args);
        }, timeout);
    };
};

export const getObjectDiff = (obj1, obj2) => {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (JSON.stringify(obj1[key]) === JSON.stringify(obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));

    return diff;
};

export const renderErrors = (errorData) => {
    if (errorData?.parse_errors?.length) {
        const [error] = errorData.parse_errors;
        return error?.reason || error?.detail || '-';
    }
    if (errorData?.errors?.length) {
        const [error] = errorData.errors;
        return error?.detail;
    }
    return '-';
};

export const getOptions = (
    arr: unknown[],
    label: string | string[] = 'name',
    value: string = 'id',
    isSort: boolean = true
): { label: string; value: string }[] =>
    arr
        ?.map((item) => ({
            label:
                typeof label !== 'string'
                    ? label
                          .reduce((acc, el) => (item?.[el] ? `${acc} ${item?.[el]}` : acc), '')
                          ?.trimStart()
                    : item?.[label] || '',
            value: item?.[value] || '',
        }))
        .sort((first, second) => {
            if (isSort) {
                const { label: a } = first || {};
                const { label: b } = second || {};
                if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
            }
            return 0;
        });

export const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const getUniqueArray = (array) =>
    array.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id));

export const getPhotos = (photos) => {
    let res = [];
    photos?.forEach((i) => {
        if (!i?.is_preview) {
            let item = photos.find(
                (e) => e.is_preview && e.extend_data?.original_file_link_id === i.id
            );
            item = item && Object.keys(item).length > 0 ? item : i;

            res.push(item);
        } else {
            res.push(i);
        }
    });
    res = getUniqueArray(res);

    return res.map((e) => {
        const photoOriginal = photos?.find((i) => i?.id === e?.extend_data?.original_file_link_id);
        return {
            ...e,
            id_original: photoOriginal?.id,
            file_created_at_original: photoOriginal?.extend_data?.file_created_at,
            file: {
                ...e.file,
                url_original: photoOriginal?.file?.url,
            },
        };
    });
};

export const extractErrorMessage = (error) => {
    const errorMessage = error?.message || error?.data?.detail || t('messages.unknownError');
    return errorMessage;
};

export const sortFilterLabels = (options) => {
    const resultOptions = options.sort((first, second) => {
        const { label: a } = first || {};
        const { label: b } = second || {};
        if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
        }
        if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
        }
        return 0;
    });
    return resultOptions;
};

export const isSettingTrue = (settingName, settings, val) => {
    const currentSetting = settings?.find((item) => item.code === settingName);
    if (currentSetting) {
        const settingValue = currentSetting.setting_values?.[0]?.val;
        return !val ? settingValue !== 'false' : settingValue === val;
    } else return false;
};

export const subscribeToAuthFails = (api, unauthorizedCallback: () => void) => {
    const interceptor = api.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            throw error;
        }
    );

    const cleanup = () => {
        api.interceptors.request.eject(interceptor);
    };

    return cleanup;
};

export const removeDuplicatesForImages = (
    images: { url: string | File; id: string | null; name?: string }[]
): { url: string | File; id: string | null; name?: string }[] => {
    const uniqueImages: { url: string | File; id: string | null; name?: string }[] = [];
    const imageSet = new Set<string>();

    images.forEach((item) => {
        const { url: image, ...other } = item || {};
        if (typeof image === 'string') {
            if (!imageSet.has(image)) {
                uniqueImages.push({ url: image, ...other });
                imageSet.add(image);
            }
        } else {
            const imageId = `${image.name}-${image.size}-${image.type}`;
            if (!imageSet.has(imageId)) {
                uniqueImages.push({ url: image, ...other });
                imageSet.add(imageId);
            }
        }
    });

    return uniqueImages;
};
