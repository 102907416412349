import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Planograms.module.scss';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import SettingsSelectors from '@redux/settings/selectors';
import PlanogramsHeader from './PlanogramsHeader/PlanogramsHeader';
import PlanogramsData from './PlanogramsData/PlanogramsData';
import PlanogramsFilters from './PlanogramsFilters/PlanogramsFilters';

export const Planograms = () => {
    const { t } = useTranslation('translation');

    const project_id = sessionStorage.getItem('project_id');
    const defaultValues = {
        project_id: project_id !== 'undefined' ? project_id : '',
        planogram_type: null,
    };

    const getFilters = (values) => {
        return values;
    };

    const { settingsAllUser: settings } = useSelector(SettingsSelectors.settingsState);
    const planogramsPermissionWebCreate =
        settings?.find((s) => s.code === 'web_planograms_permission')?.setting_value === 'CREATE';

    return (
        <Layout
            title={t('catalog.planograms.planograms')}
            renderSearch={() => ({ name: 'name', placeholder: t('common.searchName') })}
            renderHeader={(props) => (
                <PlanogramsHeader
                    {...props}
                    planogramsPermissionWebCreate={planogramsPermissionWebCreate}
                />
            )}
            renderData={(props) => (
                <PlanogramsData
                    {...props}
                    planogramsPermissionWebCreate={planogramsPermissionWebCreate}
                />
            )}
            renderFilters={PlanogramsFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    );
};
