import i18next from 'i18next';
import { getRoles } from '@tools/utils/functions';

const clientPermission = {
    [i18next.t('common.project')]: true,
    [i18next.t('feed.visitNumber')]: true,
    [i18next.t('feed.visitStart')]: true,
    [i18next.t('feed.visitEnd')]: true,
    [i18next.t('feed.signBoard')]: true,
    [i18next.t('feed.shopName')]: true,
    [i18next.t('feed.branch')]: true,
    [i18next.t('feed.address')]: true,
    [i18next.t('feed.type')]: true,
    [i18next.t('feed.startTime')]: true,
    [i18next.t('feed.endTime')]: true,
    [i18next.t('feed.category')]: true,
    [i18next.t('feed.id')]: true,
    [i18next.t('feed.comment')]: true,
    [i18next.t('statuses.completed')]: true,
    [i18next.t('feed.totalNumberPhotos')]: true,
    [i18next.t('common.outletName')]: true,
    [i18next.t('common.status')]: true,
    [i18next.t('crowd.task_type')]: true,
    [i18next.t('crowd.task_complexity')]: true,
    [i18next.t('common.city')]: true,
    [i18next.t('common.outletCode')]: true,
    [i18next.t('feed.timeSpend')]: true,
    [i18next.t('crowd.startTime')]: true,
    [i18next.t('crowd.endTime')]: true,
    [i18next.t('feed.retailsFormat')]: true,
    [i18next.t('common.retail')]: true,
    [i18next.t('common.territory')]: true,
    [i18next.t('alert.stockStatus')]: true,
    [i18next.t('alert.virtualStockQty')]: true,
    [i18next.t('alert.comment')]: true,
    [i18next.t('catalog.planograms.type')]: true,
};

const setClientPermission = (items: any) => {
    if (Array.isArray(items)) {
        return items.filter((item) => {
            return clientPermission[item.name];
        });
    } else {
        Object.keys(items).forEach((key) => {
            if (clientPermission[key]) {
                items[key].isVisible = true;
            }
        });
    }

    return items;
};
export const permissionCheck = (roles: string[], items: any) => {
    const { clientPermission } = getRoles(roles);
    if (clientPermission) return setClientPermission(items);

    Object.keys(items).forEach((key) => {
        items[key].isVisible = true;
    });

    return items;
};
