import React, { useCallback, useEffect, useState } from 'react';
import styles from './IR.module.scss';
import { t } from 'i18next';
import {
    TextField,
    Filters,
    Loader,
    TableGrid,
    Pagination,
    Select,
    PhotoSlider,
} from '@components';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uniqid } from 'uuid';
import { fetchAiletList } from '@apiFeature/ir';
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from '@tools/utils/functions';
import { FilterValue } from '@apiFeature/types';

const getPhotos = (photos_all, id) => {
    let res = [];
    for (const el in photos_all) {
        const { photos } = photos_all?.[el] || {};
        for (const item in photos) {
            const { product_ids, file, number } = photos?.[item] || {};
            if (product_ids?.length > 0 && product_ids?.some((e) => e === id)) {
                res.push({
                    ...photos?.[item],
                    file: { ...file, text: `${t('ir.photos')} ${number}` },
                });
            }
        }
    }
    return res;
};

const getParams = (items) => {
    let photos_all = {};
    let categoryOptions = [];
    let brandOptions = [];
    let facing_plan_all = 0;
    let facing_exist_all = 0;
    let osa = 0;
    let oos = 0;
    items.forEach((e) => {
        const elements = e?.report?.assortment_achievement || [];
        const photos = e?.report?.photos || {};
        for (const item in photos) {
            const { scene_id, scene_type, image_url, products } = photos?.[item];
            if (!photos_all[scene_id]) photos_all[scene_id] = { scene_type, photos: [] };
            photos_all[scene_id].photos.push({
                id: uniqid(),
                file: { url: image_url },
                product_ids: products?.length > 0 && products?.map((e) => e.product_id),
                number: photos_all[scene_id].photos.length + 1,
            });
        }

        elements.forEach((ee) => {
            const {
                facing_plan,
                facing_fact,
                category_name,
                product_category_id,
                brand_name,
                brand_id,
            } = ee || {};

            const category_el = { label: category_name, value: product_category_id };
            if (!categoryOptions.some((it) => it.value === category_el.value) && category_el.label)
                categoryOptions.push(category_el);

            const brand_el = { label: brand_name, value: brand_id };
            if (!brandOptions.some((it) => it.value === brand_el.value) && brand_el.label)
                brandOptions.push(brand_el);

            if (facing_plan && facing_fact) {
                facing_exist_all += +facing_plan || 0;
            }
            if ((facing_plan && !facing_fact) || !facing_plan) {
                facing_exist_all += 0;
            }
            facing_plan_all += +facing_plan;

            oos += facing_fact === 0 && facing_plan > 0 ? 1 : 0;
        });
    });

    osa = Math.round((facing_exist_all / facing_plan_all) * 100) || 0;

    return { options: { categoryOptions, brandOptions }, metrics: { osa, oos }, photos_all };
};

const getData = (data, currValues) => {
    const hasValues = Object.values(currValues).some(
        (value) => value !== undefined && value !== null && value !== ''
    );
    if (!hasValues) return data;

    const { items = [] } = data || {};
    const { external_id, name, product_category_ids, brand_ids, facing_plan, facing_fact } =
        currValues || {};

    const result = {
        ...data,
        items: data.items.map((e) => ({
            ...e,
            report: { ...e.report, assortment_achievement: [] },
        })),
    };
    items.forEach((e, i) => {
        const elements = e?.report?.assortment_achievement || [];
        const res = elements.filter((ee) => {
            const e = external_id ? ee.external_id === external_id : true;
            const n = name ? ee.name === name : true;
            const c =
                product_category_ids?.length > 0
                    ? product_category_ids.includes(ee.product_category_id)
                    : true;
            const b = brand_ids?.length > 0 ? brand_ids.includes(ee.brand_id) : true;
            const p =
                facing_plan === 0 || facing_plan > 0
                    ? facing_plan === 0
                        ? ee.facing_plan === 0
                        : ee.facing_plan > 0
                    : true;
            const f =
                facing_fact === 0 || facing_fact > 0
                    ? facing_fact === 0
                        ? ee.facing_fact === 0
                        : ee.facing_fact > 0
                    : true;

            return e && n && c && b && p && f;
        });
        if (res?.length > 0) result.items[i].report.assortment_achievement.push(...res);
    });
    return result;
};

const defaultItemsPerPage = {
    label: '1',
    value: 1,
};

const columns = [
    {
        field: 'external_id',
        headerName: t('ir.code'),
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'name',
        headerName: t('ir.productName'),
        flex: 1,
        sortable: false,
    },
    {
        field: 'facing_plan',
        headerName: t('ir.facing_plan'),
        minWidth: 50,
        sortable: false,
    },
    {
        field: 'facing_fact',
        headerName: t('ir.facing_fact'),
        minWidth: 100,
        sortable: false,
    },
    {
        field: 'price',
        headerName: t('ir.price'),
        minWidth: 100,
        sortable: false,
    },
    {
        field: 'price_type',
        headerName: t('ir.price_type'),
        valueGetter: ({ value }) => (value === 0 ? t('ir.price_type_1') : t('ir.price_type_2')),
        minWidth: 100,
        sortable: false,
    },
    {
        field: 'photos',
        headerName: t('ir.photos'),
        renderCell: ({ value }) => {
            if (value) {
                return value?.length > 0 && <PhotoSlider photos={value} type="listText" />;
            } else return '';
        },
        minWidth: 100,
        sortable: false,
    },
];

const IR = ({ visit_id }) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const pageNumber = 1;
    const [itemsPerPage, setItemsPerPage] = useState<FilterValue>(defaultItemsPerPage);
    const limit = Number(itemsPerPage.value);
    const offsetFromPageNumber = (pageNumber - 1) * limit;
    const paginationModelDefault = {
        offset: offsetFromPageNumber > 0 ? offsetFromPageNumber : 0,
        limit,
    };
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [defaultData, setDefaultData] = useState({});
    const [data, setData] = useState({});
    const [options, setOptions] = useState({});
    const [metrics, setMetrics] = useState({});
    const [photosAll, setPhotosAll] = useState({});
    const { items = [], total = 0, offset } = data || {};
    const { categoryOptions, brandOptions } = options || {};
    const { osa, oos } = metrics || {};

    let defaultValues: Record<string, any> = {
        external_id: '',
        name: '',
        product_category_ids: null,
        brand_ids: null,
        facing_plan: null,
        facing_fact: null,
    };
    const form = useForm({ defaultValues });
    const { watch, reset, setValue } = form;
    const currValues = watch();
    const { external_id, name, product_category_ids, brand_ids, facing_plan, facing_fact } =
        currValues;

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        reset(defaultValues);
    };

    const yesNoOptions = [
        {
            label: t('common.yes'),
            value: 1,
        },
        {
            label: t('common.no'),
            value: 0,
        },
    ];

    const baseFilters = [
        {
            type: TextField,
            name: 'external_id',
            label: t('ir.code'),
            placeholder: t('ir.enterCode'),
        },
        {
            type: TextField,
            name: 'name',
            label: t('ir.productName'),
            placeholder: t('common.enterName'),
        },
        {
            type: Select,
            name: 'product_category_ids',
            label: t('ir.category_name'),
            onChange,
            value: product_category_ids
                ? categoryOptions?.filter((e) => product_category_ids.includes(e.value))
                : null,
            options: categoryOptions,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'brand_ids',
            label: t('ir.brand_name'),
            onChange,
            value: brand_ids ? brandOptions?.filter((e) => brand_ids.includes(e.value)) : null,
            options: brandOptions,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'facing_plan',
            label: t('ir.facing_plan_name'),
            onChange,
            value: yesNoOptions?.find((e) => e.value === facing_plan),
            options: yesNoOptions,
            isClearable: true,
        },
        {
            type: Select,
            name: 'facing_fact',
            label: t('ir.facing_fact_name'),
            onChange,
            value: yesNoOptions?.find((e) => e.value === facing_fact),
            options: yesNoOptions,
            isClearable: true,
        },
    ];

    const getAiletList = useCallback(
        (offset = 0, limit) => {
            if (visit_id) {
                setLoading(true);
                fetchAiletList({
                    object_id: visit_id,
                    pagination: {
                        limit,
                        offset,
                    },
                })
                    .then((res) => {
                        const { ailet_reports } = res || {};
                        const { items } = ailet_reports || {};
                        if (items?.length > 0) {
                            const { options, metrics, photos_all } = getParams(items);

                            const res = items.map((e) => ({
                                ...e,
                                report: {
                                    ...e?.report,
                                    assortment_achievement: e?.report?.assortment_achievement.map(
                                        (ee) => ({
                                            ...ee,
                                            photos: getPhotos(photos_all, ee.id),
                                        })
                                    ),
                                },
                            }));

                            const data = { ...ailet_reports, items: res };

                            setDefaultData(data);
                            const result = getData(data, currValues);
                            setData(result);

                            setOptions(options);
                            setMetrics(metrics);
                            setPhotosAll(photos_all);
                        }
                    })
                    .catch((error) => {
                        enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [visit_id]
    );

    useEffect(() => {
        const { offset, limit } = paginationModel;
        getAiletList(offset, limit);
    }, [paginationModel?.offset]);

    useEffect(() => {
        if (Object.keys(defaultData)?.length > 0) {
            const res = getData(defaultData, currValues);
            setData(res);

            const { metrics } = getParams(res?.items);
            setMetrics(metrics);
        }
    }, [external_id, name, product_category_ids, brand_ids, facing_plan, facing_fact]);

    return (
        <>
            {loading && <Loader />}
            <div>
                <div className={styles.metrics}>
                    {'osa' in metrics && (
                        <div className={styles.item}>
                            <div className={styles.name}>OSA</div>
                            <div className={styles.value}>{osa}%</div>
                        </div>
                    )}
                    {'oos' in metrics && (
                        <div className={styles.item}>
                            <div className={styles.name}>OOS</div>
                            <div className={styles.value}>{oos}</div>
                        </div>
                    )}
                </div>
                <FormProvider {...form}>
                    <Filters
                        className={styles.baseFilters}
                        defaultValues={defaultValues}
                        filters={baseFilters}
                        isHeader
                        handleReset={handleReset}
                        form={form}
                    />
                </FormProvider>
                {items.map(
                    (e) =>
                        e?.report?.assortment_achievement && (
                            <TableGrid
                                key={e.id}
                                rows={e?.report?.assortment_achievement}
                                columns={columns}
                                loading={loading}
                                getRowHeight={() => 'auto'}
                                className={styles.tableGrid}
                                initialState={{
                                    pagination: { paginationModel: { pageSize: 20 } },
                                }}
                                pageSizeOptions={[20, 50, 100]}
                                disableRowSelectionOnClick
                            />
                        )
                )}
                {items?.length > 1 && (
                    <Pagination
                        total={total}
                        limit={limit}
                        offset={offset}
                        pageNumber={pageNumber}
                        hasNumbersPagination={true}
                        isSearchParams={false}
                        onPaginationModelChange={setPaginationModel}
                        setItemsPerPage={setItemsPerPage}
                        className={styles.pagination}
                    />
                )}
            </div>
            {Object.keys(photosAll)?.length > 0 && (
                <div className={styles.photosAll}>
                    {Object.keys(photosAll).map((e) => (
                        <div key={e}>
                            <div className={styles.photosTitle}>{photosAll[e].scene_type}</div>
                            <PhotoSlider id={e} photos={photosAll[e].photos} type="list" isSmall />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default IR;
