import React from 'react';
import styles from './PlanogramsHeader.module.scss';
import { Button } from '@components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Book } from '@navigation/Book';

const PlanogramsHeader = ({ planogramsPermissionWebCreate }) => {
    const { t } = useTranslation('translation');
    const navigation = useNavigate();

    return (
        <>
            {planogramsPermissionWebCreate && (
                <div
                    className={styles.renderHeader}
                    onClick={() => {
                        navigation(Book.catalog.planogram_create);
                    }}
                >
                    <Button variant="outlined">{t('catalog.planograms.create')}</Button>
                </div>
            )}
        </>
    );
};

export default PlanogramsHeader;
